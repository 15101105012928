// @use 'node_modules/bootstrap/scss/functions' as *;
@use "local_modules/bbr-bootstrap/theme/_variables.scss" as *;
@import "../../../../styles/_variables-custom.scss";
.container {
  position: relative;
}
.header {
  min-height: $spacer * 4.35;
  width: 100%;
  display: flex;
  align-items: center;
  position: relative;
  padding-left: 2rem;
  overflow: hidden;
  color: $primary;
  cursor: pointer;
  &:focus {
    box-shadow: none;
    background-color: $button-focus-color;
  }

  .expand {
    position: absolute;
    top: 50%;
    transform: translateY(-50%);
    right: 1rem;
    svg {
      margin-top: 6px;
    }
  }
}
