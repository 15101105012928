@use "sass:math";
@use "local_modules/bbr-bootstrap/theme/_variables.scss" as *;
@import "../../../../styles/_variables-custom.scss";
@import "../../Admin.module.scss";
.container {
  width: 100%;
  max-width: $spacer * 70;
  margin: 0 auto;
}

.listHeader {
  margin-top: $spacer;
  > div {
    margin-right: math.div(2, 3) * $spacer;
  }
  .titel {
    flex: 3 1 0px;
  }
  .forfatter {
    flex: 2 1 0px;
    text-align: justify;
  }
  .dato {
    flex: 2 1 0px;
  }
  .tema {
    flex: 2 1 0px;
  }
  .forside {
    flex: 1 1 0px;
  }
}

@media all and (max-width: $break-mobile) {
  .listHeader {
    display: none;
  }
}
