@use "local_modules/bbr-bootstrap/theme/_variables.scss" as *;
@use "../../../styles/_variables.scss" as *;
@import "../../../styles/_variables-custom.scss";
.container {
  padding: $spacer;

  h1,
  h2,
  h3 {
    color: $info;
  }

  h1 {
    font-size: 1.7rem;
    margin-bottom: 2 * $spacer;
  }

  h2 {
    font-size: 1.2rem;
    margin-top: 2em;
  }

  article {
    font-size: 1.1rem;
    p {
      margin: 1em 0;
      line-height: 150%;
    }
  }
}
