@use "local_modules/bbr-bootstrap/theme/_variables.scss" as *;
@use "../../styles/_variables.scss" as *;
@import "../../styles/_variables-custom.scss";

.badge {
  background-color: $body-secondary-bg;
  color: $primary;
  padding: 0.15em 0.5em;
  line-height: 1.25;
  border-radius: $border-radius;
  display: inline-flex;
  justify-content: center;
  align-items: center;
  font-size: 0.75em;
  font-weight: 700;
}
