@use "local_modules/bbr-bootstrap/theme/_variables.scss" as *;
@use "../../styles/_variables.scss" as *;
@import "../../styles/_variables-custom.scss";

.content {
  padding-top: $navbar-height + 3 * $spacer !important;
  background-color: $body-tertiary-bg;
}

.container {
  width: 100%;
  max-width: $spacer * 70;
  margin: 6rem auto 0 auto;
  h2 {
    @include main-h2;
  }
}

ul.listUnstyled {
  list-style: inside !important;
  margin-bottom: 0.75rem;

  li {
    list-style: unset;
    margin-bottom: 0.5em;
  }
}

// Search Result List
.listGroupItem {
  background-color: transparent;
  &:hover {
    background-color: $white;
  }
}
