//pagination fejllister
@media all and (max-width: $break-tablet-md) {
}

@media all and (max-width: $break-mobile) {
  .paging {
    width: 100%;
    .text {
      flex-grow: 1;
      padding: 0 0.5em;
      overflow: hidden;
    }
    .mobile {
      display: none;
    }
  }
}

//navigation
@media all and (max-width: $break-tablet-sm) {
  .links {
    display: none;
  }
  .c-nav-search {
    & .ask-component {
      width: 80%;
    }
  }
}
@media all and (min-width: ($break-tablet-sm + 1px)) {
  .c-mobile-nav,
  div.c-mobile-nav {
    display: none;
  }
}

@media all and (max-width: $break-mobile) {
  .linkscontainer {
    margin-left: 0;
  }

  .c-mobile-nav {
    margin: 0 1rem;
  }

  .hamburgermenu {
    margin-top: 1.5rem !important;
    margin-right: -50vw;
    width: 100vw;
    border-left-color: transparent !important;
    border-right-color: none !important;
    border-top-color: $secondary !important;
    border-bottom-color: $secondary !important;
  }
}
