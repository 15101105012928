@use "local_modules/bbr-bootstrap/theme/_variables.scss" as *;
@use "../../styles/_variables.scss" as *;
@import "../../styles/_variables-custom.scss";

.avatar {
  background-color: $info-bg-100;
  color: $info;
  border-radius: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
  height: $spacer * 2;
  width: $spacer * 2;
  font-weight: 600;
  font-size: 0.9rem;
  &__lg {
    font-size: 1rem;
    height: $spacer * 2.5;
    width: $spacer * 2.5;
  }
  &__iscurrentuser {
    background-color: $primary;
    color: white;
  }
}
