$table-border-color: #dee7eb;

.table-container {
  width: 100%;
  overflow-x: auto;
  margin-bottom: 1.5rem;
  flex: 1 1 100%;
  padding: 0;

  .table-heading {
    padding: 1rem 1.5rem 0 1.5rem;
    margin: 0 !important;
    font-weight: 700 !important;
    font-size: 0.8em !important;
  }

  &--energi {
    border-width: 1px 1px 1px 1px;
    border-style: solid;
    border-color: $table-border-color;
    background-color: $body-secondary-bg;
  }

  &--areal {
    display: flex;
    border: 1px solid $table-border-color;
    margin: 2rem 0;

    .col {
      display: flex;
      flex-direction: column;
      gap: 1rem;
      border-right: 1px solid $table-border-color;

      &:last-child {
        border: none;
      }
    }
  }
}

.c-table {
  border-collapse: collapse;
  border-spacing: 0;
  width: 100%;
  // min-width: 250px;

  thead tr {
    background-color: $body-secondary-bg !important;
  }

  th,
  td {
    text-align: left;
  }

  th {
    font-weight: 700;
    font-size: 0.8em;
    color: #5c6161;
    padding: 0.5rem 1.5rem 1rem 1.5rem;
    line-height: 1.35em;
  }

  td {
    font-size: 1.06em;
    padding: 1rem 1.5rem;
  }

  &--energi {
    table-layout: fixed;

    tr {
      &:nth-child(odd) {
        background-color: #fff;
      }
    }

    .right {
      text-align: right;
    }

    .left {
      text-align: left;
    }

    .nokwh {
      min-width: 0px;
      max-width: 90px;
      text-align: left;
      display: block;
    }

    .kwh {
      min-width: 0px;
      max-width: 50px;
      text-align: right;
      display: block;
    }
  }

  &--areal {
    line-height: 1.4em;
    table-layout: fixed;

    td {
      padding: 0.5rem 0 0.5rem 1.5rem;
      vertical-align: bottom;
    }

    td + td {
      padding-right: 1.5rem;
    }

    tr {
      &:last-child td {
        padding-bottom: 1rem;
      }

      &.sub-row {
        color: $text-muted;
        font-size: 0.95em;

        td:first-child {
          padding-left: 2.5rem;
        }
      }

      &:not(.sub-row) td {
        padding-top: 1rem;
        font-weight: 500;
      }
    }

    th {
      color: $text-muted;
      padding-top: 0;
      padding-bottom: 0;
      height: 3.5rem;
    }

    th + th {
      width: 30%;
      text-align: right;
    }

    td + td {
      text-align: right;
    }

    + .c-table--areal {
      border-top: 1px solid $table-border-color;
    }
  }
}

@media all and (max-width: $break-mobile) {
  .table-container {
    &--areal {
      flex-wrap: wrap;

      .col {
        border-right: unset;
      }
    }
  }
}
