@use "local_modules/bbr-bootstrap/theme/_variables.scss" as *;
@import "../../../../styles/_variables-custom.scss";
@import "../../Admin.module.scss";

.container {
  margin-top: 1rem;
  ul {
    padding: 1rem 0;
  }
  li {
    display: flex;
    button {
      width: unset;
      margin-left: 1rem;
    }
  }
}
.additem {
  button {
    color: $secondary-text;
    font-size: 1.1rem;
    padding: 0.5 * $spacer $spacer;
    display: flex;
    align-items: center;
    span {
      margin-left: 0.5rem;
    }
    &:hover {
      color: $primary;
    }
  }
}

ul.list {
  padding-bottom: 0;
  li {
    margin-bottom: 0.5rem;
  }
}
