@use "sass:math";
@use "local_modules/bbr-bootstrap/theme/_variables.scss" as *;
@import "../../../../styles/_variables-custom.scss";
@import "../../Admin.module.scss";
.container {
  width: 100%;
  display: flex;
  align-items: flex-start;
  justify-content: space-between;

  transition: box-shadow 300ms, border 300ms;
  border-bottom: solid 1px $secondary-bg-300;
  position: relative;
  padding: $spacer;
  text-overflow: ellipsis;

  &:hover {
    background-color: $white;
  }
  > div {
    margin-right: math.div(2, 3) * $spacer;
  }
  .navn {
    flex: 3 1 0px;
  }
  .beskrivelse {
    flex: 3 1 0px;
    text-align: justify;
    margin-right: $spacer;
  }
  .antal {
    flex: 1 1 0px;
  }
  .listetype {
    display: flex;
    flex: 1 1 0px;
  }
  .status {
    flex: 1 1 0px;
    p {
      &.udgaaet {
        font-style: italic;
      }
      &.passiv {
        font-style: italic;
      }
    }
  }
  p {
    text-align: left;
  }

  .circle {
    height: $spacer;
    width: $spacer;
    border-radius: 50%;
    display: block;
    margin-right: $spacer;
    &.skal,
    &.kan {
      background-color: $warning;
    }
  }
}

@media all and (max-width: $break-mobile) {
  .container {
    flex-direction: column;
    .antal,
    .listetype,
    .status {
      display: none;
    }
  }
}
