@use "sass:math";
@use "node_modules/bootstrap/scss/functions" as *;
@use "local_modules/bbr-bootstrap/theme/_variables.scss" as *;
@use "../../../styles/_variables.scss" as *;
@import "../../../styles/_variables-custom.scss";

.button {
  cursor: pointer;
  display: flex;
  align-items: center;
  justify-content: center;
  text-align: left;
  padding: $spacer $spacer * 3 * 0.5;
  border: 1px solid transparent;
  font: inherit;
  font-weight: 600;
  transition: border 300ms, color 300ms, background-color 300ms;
  border-radius: 4px;
}

.primary,
.primary_lg {
  justify-content: space-between;
  border-color: $primary;
  background-color: transparent;
  color: $primary;
  // for arrow
  svg {
    margin-right: 5px;
    transition: margin 200ms ease;
    flex-shrink: 0;
  }
  span {
    width: calc(100% - 35px);
  }
  &:hover {
    background-color: $primary;
    color: #fff;
    svg {
      margin-right: 0;
    }
  }
  &:active,
  .selected {
    transition: border 300ms;
    background-color: $secondary-bg-300;
    color: $primary;
    box-shadow: none;
  }

  &:focus {
    box-shadow: 0 0 0 1px inset $primary, 0 0 0 3px inset $secondary-bg-300;
  }
}

.primary {
  height: $spacer * 3;
  padding: 0 $spacer * 3 * 0.5;
}

.primary_lg {
  border-width: 2px;
  height: $spacer * 4;
  padding: 0 $spacer * 2.5;
  font-size: 1.1rem;
}

.x_small {
  height: $spacer * 2.55;
  padding: 0 $spacer * 3 * 0.5;
  color: $info;
  background-color: transparent;
  border: 1px solid $info;
  justify-content: center;
  svg {
    display: inline-block;
    margin-right: math.div($spacer * 2, 3);
  }
  &:hover {
    border: 1px solid $info;
  }
  &.active {
    background-color: $info;
    color: #fff;
  }
}

.x_large {
  min-height: $spacer * 7.5;
  padding: 1.5 * $spacer 2 * $spacer;
  border: 1px solid $border-color;
  box-shadow: $box-shadow;
  &:hover {
    border-color: $primary;
  }
}

.small {
  height: $spacer * 3;
  padding: 0 $spacer * 3 * 0.5;
  color: $info;
  background-color: transparent;
  border: 1px solid $info;
  justify-content: center;
  svg {
    display: inline-block;
    margin-right: math.div($spacer * 2, 3);
  }
  &:hover {
    border: 1px solid $info;
  }
  &.active {
    background-color: $info;
    color: #fff;
  }
  &.color_green {
    border-color: $primary;
    &:hover {
      border-color: darken($primary, 10%);
    }
    &.active {
      background-color: darken($primary, 10%);
    }
  }
  &[disabled] {
    pointer-events: none;
    opacity: 0.25;
  }
}

.small_filled {
  background-color: $info;
  color: white;
  @extend .small;
  &:hover {
    background-color: darken($info, 10%);
    color: white;
  }
  &.active {
    background-color: darken($info, 10%);
    color: #fff;
  }
  &.color_green {
    background-color: $primary;
    &:hover {
      background-color: darken($primary, 10%);
      color: white;
    }
    &.active {
      background-color: darken($primary, 10%);
      color: #fff;
    }
  }
}

.small_light {
  background-color: $body-tertiary-bg;
  font-size: 0.85em;
  font-weight: 600;
  padding: 0.8em;
  display: flex;
  white-space: nowrap;
  align-items: center;
  width: $spacer * 6.4;
  justify-content: space-between;
  transition: all 200ms ease;
  svg {
    transform: rotate(180deg);
    margin-left: 5px;
    transition: margin 200ms ease;
  }
  &:hover {
    color: $black;
    background-color: $secondary-bg-subtle;
    svg {
      margin-left: 0;
    }
  }
}

@media all and (max-width: $break-laptop) {
  .small_light {
    padding: 0.8em 0.5em;
    width: $spacer * 7;
  }
}

.icon {
  width: 3rem !important;
  height: 3rem !important;
  padding: 0 !important;
  color: $info;
  border-color: $info;
  &:hover {
    border-color: $info;
    background-color: $info;
    color: #fff;
  }
}

.inverse {
  color: white;
  background-color: black;
  &:hover {
    border: 1px solid black;
  }
}
