@use "node_modules/bootstrap/scss/functions" as *;
@use "local_modules/bbr-bootstrap/theme/_variables.scss" as *;
@use "../../../styles/_variables.scss" as *;
@import "../../../styles/_variables-custom.scss";

.container {
  position: relative;
  border-top: 1px solid $secondary;
}
.header.small {
  min-height: $spacer * 2.8;
  overflow: hidden;
  padding-left: 0;
  padding-right: 2rem;
  h4 {
    font-size: 1em;
    margin: 0.5em 2rem 0.5em 0;
  }
  .expand {
    position: absolute;
    left: auto;
    right: 0.6em;
    svg {
      margin-top: 0;
    }
  }
}

.smallcontainer {
  padding: 0;
  border-width: 1px 0;
  border-style: solid;
  border-color: $border-color;
  margin-bottom: 1.5rem;
}

.header {
  min-height: $spacer * 4.35;
  width: 100%;
  display: flex;
  align-items: center;
  position: relative;
  padding-left: 3rem;
  overflow: hidden;
  color: $primary;
  cursor: pointer;
  &[aria-expanded="true"]:focus {
    box-shadow: none;
  }

  h4 {
    font-size: 1.3em;
    font-weight: 400;
    margin: 0.5em 2rem 0.5em 0;
    word-break: break-word;
    hyphens: auto;
    .prioritet {
      color: blue;
    }
  }
  .expand {
    position: absolute;
    top: 50%;
    transform: translateY(-50%);
    left: 0;
    line-height: 100%;
    display: flex;
    align-items: center;
    color: $primary;
  }
  .opgaveIcon {
    position: absolute;
    top: 50%;
    transform: translateY(-50%);
    right: 0;
  }
}

@media all and (max-width: $break-mobile) {
  .header {
    padding-left: 3rem;
    h4 {
      font-size: 1.2em;
    }
    .expand {
      svg {
        width: 26px;
        height: 26px;
      }
    }
    .opgaveIcon {
      right: 0;
      > div {
        width: auto;
        > span {
          display: none;
        }
      }
    }
  }
}
