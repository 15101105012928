@use "sass:math";
@use "node_modules/bootstrap/scss/functions" as *;
@use "local_modules/bbr-bootstrap/theme/_variables.scss" as *;
@use "../../../styles/_variables.scss" as *;
@import "../../../styles/_variables-custom.scss";

.container {
  display: flex;
  flex-wrap: wrap;
  justify-content: stretch;
  background: $info-bg-100;
  padding: 1.5rem 0 0 0;
  margin-top: $spacer;
}

.content {
  width: 100%;
}

.header {
  padding: 0 1.5rem;
  color: $info;
  display: flex;
  line-height: 140%;
  flex-wrap: wrap;
  justify-content: space-between;
  align-items: center;
  width: 100%;
  i {
    font-style: normal;
    opacity: 0.7;
  }
  h4 {
    color: inherit;
    font-size: 1.07em;
  }
}

.content {
  padding: 0 1.5 * $spacer;
}

.hr {
  border-color: darken($border-color, 20%);
}

.list-group--custom {
  --bs-list-group-item-padding-x: 0;
  --bs-list-group-item-padding-y: 0.85rem;
  --bs-list-group-bg: transparent;

  table {
    th {
      font-weight: inherit;
      &:after {
        content: ":";
      }
    }
    td {
      padding: 0 2rem;
    }
  }
}
