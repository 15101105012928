@use "sass:math";
@use "node_modules/bootstrap/scss/functions" as *;
@use "local_modules/bbr-bootstrap/theme/_variables.scss" as *;
@use "../../styles/_variables.scss" as *;
@import "../../styles/_variables-custom.scss";
.container {
  width: 100%;
  max-width: $spacer * 70;
  margin: 0 auto;
  overflow-y: scroll;

  h1 {
    font-size: 1.7em;
    font-weight: 400;
    margin-bottom: 1.3rem;
  }

  label,
  legend {
    font-weight: 400;
    color: $primary;
    font-size: 1.2rem;
  }

  form > label,
  .indhold > label {
    margin: 0.3em 0;
  }

  fieldset {
    border: 0;
    padding: 0;
    margin: 0.3em 0;
    min-width: 0;
    flex: 1 1 100%;
    legend {
      padding: 0;
      margin: 0 0 1em 0;
    }

    label {
      display: inline-block;
    }
  }
  input,
  select {
    font-size: 1.2rem;
    color: $primary;
    transition: 200ms;
    margin-bottom: 2 * $spacer;
  }

  input[type="text"],
  input[type="url"],
  input[type="submit"],
  select {
    width: 100%;
    padding: 0 $spacer;
    height: 4 * $spacer;

    border: none;
    background-image: none;
    background-color: transparent;
    -webkit-box-shadow: none;
    -moz-box-shadow: none;
    box-shadow: none;
  }

  input[type="submit"]:disabled,
  input[type="submit"]:disabled:hover {
    cursor: not-allowed;
    transition: none;
    background: transparent;
    color: $secondary-text;
    border-color: $secondary-text;
  }

  input[type="text"],
  input[type="url"],
  select {
    border: 1px solid #fff;
    background-color: #fff;
    &:hover {
      border: 1px solid $border-color;
    }
    &:focus {
      border: 1px solid $primary;
    }
  }

  input[type="file"] {
    border: 1px solid $secondary-bg-subtle;
    background-color: $secondary-bg-subtle;
    line-height: 2 * $spacer;
    padding: math.div(2, 3) * $spacer;
    width: 100%;
    &:hover {
      border: 1px solid $border-color;
    }
    &:focus {
      border: 1px solid $primary;
    }
  }

  input[type="file"],
  input[type="submit"],
  select {
    cursor: pointer;
  }

  input[type="submit"] {
    background: $secondary-bg-300;
    border: 1px solid $primary;
    transition: 200ms;
    font-size: 1.3em;
    font-weight: 500;
    margin: 3 * $spacer 0 $spacer 0;
    &:hover {
      color: #fff;
      background: $primary;
    }
    &:focus {
      box-shadow: 0 0 0 2px $primary;
    }
  }

  .selectbox {
    display: flex;
    width: 100%;
    position: relative;
    select {
      appearance: none;
      -moz-appearance: none;
      -webkit-appearance: none;
    }

    select::-ms-expand {
      display: none;
    }
    .arrowdown {
      transform: rotate(90deg);
      position: absolute;
      top: 18px;
      right: 20px;
      z-index: 10;
    }
  }

  .check {
    position: relative;
    padding-left: 32px;
    margin-right: 2 * $spacer;
    cursor: pointer;
    -webkit-user-select: none;
    -moz-user-select: none;
    -ms-user-select: none;
    user-select: none;
    .checkboxlabel {
      margin-right: 1rem;
    }
    input {
      position: absolute;
      opacity: 0;
      cursor: pointer;
    }

    .radiobutton,
    .checkbox {
      position: absolute;
      top: 0;
      height: 20px;
      width: 20px;
      background-color: transparent;
      border: 1px solid $secondary-bg-300;
    }
    .radiobutton {
      border-radius: 50%;
      left: 0;
      &:after {
        content: "";
        position: absolute;
        top: 5px;
        left: 5px;
        width: 8px;
        height: 8px;
        border-radius: 50%;
        background: $primary;
        display: none;
      }
    }

    .checkbox {
      margin: 3px 0;
      &:after {
        content: "";
        position: absolute;
        background: $primary;
        display: none;
        left: 6px;
        top: 2px;
        width: 6px;
        height: 11px;
        border: solid $secondary-bg-subtle;

        border-width: 0 2px 2px 0;
        transform: rotate(40deg);
      }
    }
    &:hover input ~ .radiobutton,
    &:hover input ~ .checkbox {
      background-color: $secondary-bg-subtle;
    }

    input:focus ~ .radiobutton,
    input:focus ~ .checkbox {
      // outline: 2px auto $secondary-bg-300;
      box-shadow: 0 0 0 2px $secondary-bg-300;
    }

    input:checked ~ .radiobutton,
    input:checked ~ .checkbox {
      border: 1px solid $primary;
      &:after {
        display: block;
      }
    }
    input:checked ~ .radiobutton {
      background-color: transparent;
    }
    input:checked ~ .checkbox {
      background-color: $primary;
    }
  }
}

.placeholder {
  font-size: 1.75rem;
  color: $secondary-text;
  width: 80%;
  margin: 7% auto;
  .arrow {
    display: inline-block;
    transform: rotate(-140deg);
  }

  p {
    margin-top: $spacer;
  }
}

li {
  button {
    width: 100%;
  }
}

.listHeader {
  width: 100%;
  display: flex;
  justify-content: space-between;
  flex-direction: row;

  border-bottom: solid 1px $secondary-bg-300;
  position: relative;
  padding: $spacer;

  h2 {
    width: 100%;
    max-width: $spacer * 70;
    margin: 0 auto;
  }
}
.listrow {
  width: 100%;
  display: flex;
  align-items: flex-start;
  justify-content: space-between;

  transition: box-shadow 300ms, border 300ms;
  border-bottom: solid 1px $secondary-bg-300;
  position: relative;
  padding: $spacer;
  text-overflow: ellipsis;
  &:hover {
    background-color: $light;
  }
  div {
    text-align: left;
  }
}

.flexitem {
  &_1 {
    flex-basis: 33.3%;
  }
  &_2 {
    flex-basis: 66.6%;
  }
}

@media all and (max-width: $break-mobile) {
  .container {
    .check {
      margin-bottom: 10px;
    }
  }
}

.twobuttons {
  display: flex;
  max-width: 300px;
  button,
  .dummy {
    flex: 1;
  }
  .cta {
    color: black;
    background-color: $secondary-bg-300;
  }
}
