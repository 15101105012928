// NB: KUN BOOTSTRAP -> vurdér hvilke som skal blive en del af temaet permanent !

// Typography
$h1-font-size: 1.4rem;
$h2-font-size: 1.35rem;
$h3-font-size: 1.15rem;
$h4-font-size: 1rem;
$h5-font-size: 0.875rem;
$h6-font-size: 0.8rem;

$headings-margin-bottom: 0;

$grid-breakpoints: (
  xs: 0,
  sm: 576px,
  md: 768px,
  lg: 992px,
  xl: 1200px,
  xxl: 1550px,
);

//containers
$container-max-widths: (
  sm: 540px,
  md: 720px,
  lg: 960px,
  xl: 1100px,
  xxl: 1220px,
);

$btn-font-size-lg: 1.05rem;
$btn-font-weight: 500;
