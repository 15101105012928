.legend {
  display: none;
}
.SKAT-BaseMap-SimpleLayerSwitcherControl {
  right: 54px;
  display: none;
}
.ol-legend {
  right: 10px;
  display: none;
}

.ol-zoom {
  right: 10px;
  display: none;
}

.map-container {
  width: 100%;
  left: unset;
  overflow: hidden;
}

.SKAT-BaseMap-Copyright {
  max-width: 100%;
  font-size: 0.7em;
  .ol-attribution {
    // white-space: normal;
    li {
      margin: 0 0.8em;
    }
  }
}
