@use "local_modules/bbr-bootstrap/theme/_variables.scss" as *;
@use "../../../styles/_variables.scss" as *;
@import "../../../styles/_variables-custom.scss";

.item {
  &.markeret {
    p {
      padding: 0 5px;
      background: $warning;
    }
  }
}
