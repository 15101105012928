.c-filter-btn {
  &:hover {
    background-color: $secondary-bg-subtle;

    &[aria-label="Sortér"] {
      position: relative;
      &::before {
        content: "";
        position: absolute;
        bottom: -1px;
        right: -1px;
        height: calc(100% + 2px);
        width: 1px;
        background-color: $primary;
        z-index: 10;
      }
    }
  }
  &:active {
    background-color: $secondary-bg-subtle;
  }
}
// .c-filter-options {
//   position: absolute;
//   z-index: 500;
// }

.filtermenuitem {
  transition: background-color 300ms;
  background-color: $white;

  .check {
    position: absolute;
    // left: math.div(2, 3) * $spacer;
  }
  .sortarrow {
    position: absolute;
    // right: math.div(2, 3) * $spacer;
  }
  &:hover {
    background-color: $secondary-bg-subtle;
  }
}

.filterswrapper {
  border: solid 1px $secondary;
  padding: $spacer;
  margin-bottom: $spacer;
  .filters {
    display: flex;
    gap: 1rem;
    @media all and (max-width: $break-tablet-md) {
      display: block;
    }
    .filterlabel {
      margin-top: 1rem;
      display: flex;
      flex-direction: column;
      gap: 1rem;
      @media all and (max-width: $break-tablet-md) {
        display: inline-block;
        margin-right: 1em;
      }
      @media all and (max-width: $break-mobile) {
        display: block;
        margin-right: 0;
      }
      > div {
        @media all and (max-width: $break-tablet-md) {
          margin-top: 1em;
        }
        > button {
          @media all and (max-width: $break-mobile) {
            display: flex;
            width: 100%;
            max-width: initial !important;
          }
        }
        > div {
          @media all and (max-width: $break-mobile) {
            width: 100%;
            margin-top: 0.25em;
          }
        }
      }
    }
    .filtermenuitem {
      padding: 0;
      height: 3rem;
      .statuslabel {
        display: flex;
        height: 100%;
        min-width: 200px;
        align-items: center;
        font-size: 0.8rem;
        padding-left: 1rem;
        input {
          margin-right: 1rem;
        }
      }
    }
    .filterfooter {
      @media all and (max-width: $break-tablet-md) {
        display: block;
        width: 100%;
        padding-top: 2em;
        max-width: 300px;
      }
      @media all and (max-width: $break-mobile) {
        max-width: initial;
      }
    }
  }
}
