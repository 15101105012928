@use "sass:math";
@use "node_modules/bootstrap/scss/functions" as *;
@use "local_modules/bbr-bootstrap/theme/_variables.scss" as *;
@use "../../../styles/_variables.scss" as *;
@import "../../../styles/_variables-custom.scss";

// Grund container
.container {
  background-color: $white;

  ul {
    display: flex;
    flex-wrap: wrap;
    padding: $spacer 0 3 * 0.5 * $spacer 0;

    li {
      flex: 1 1 40%;
      padding: $spacer 3 * 0.5 * $spacer;

      &:nth-of-type(4n + 3),
      &:nth-of-type(4n + 3) + * {
        background-color: transparent;
      }

      &:nth-of-type(4n + 1),
      &:nth-of-type(4n + 1) + * {
        background-color: $body-secondary-bg;
      }
    }
  }

  p {
    font-size: 1.06em;
    display: inline-block;
  }

  sup {
    font-size: 0.65em;
    font-weight: 600;
  }
}

.content {
  padding-top: 0;
  min-height: calc(100vh - #{$navbar-height + $subheader-height});
  max-width: $spacer * 75;
  margin: 0 auto;
}

.header {
  display: flex;
  align-items: center;
  height: $pane-header-height;
  margin-bottom: 0.5 * $spacer;
  justify-content: space-between;

  button {
    color: $primary;
    opacity: 0.9;
    height: 3 * $spacer;
    margin-top: 5px;
    font-weight: 400;
    padding: 0;
    display: flex;
    align-items: center;
    transition: 0.2s;

    svg {
      margin-right: math.div(2, 3) * $spacer;
    }

    &:hover {
      opacity: 1;
    }
  }

  p {
    margin-top: 5px;
    color: $secondary-text;
    display: flex;
    align-items: center;
    font-size: 0.95em;

    svg {
      color: $secondary-text;
      opacity: 0.6;
      margin-right: 0.5 * $spacer;
    }
  }
}

.box {
  width: 100%;
  background-color: #fff;
  box-shadow: 0 0 20px 0 rgba(53, 66, 69, 0.1);
  margin-bottom: 1rem;
  height: 5.5rem;
  padding: 0 1.5rem;
  display: flex;
  align-items: center;
  color: #003c16;

  div,
  span,
  svg {
    font-size: 1.3em;
    margin-left: $spacer;
  }
}

@media all and (max-width: $break-laptop) {
  .content {
    padding: 0 1.5 * $spacer 2 * $spacer 1.5 * $spacer;
  }
}

@media all and (max-width: $break-mobile) {
  .content {
    padding: 0 $spacer $spacer $spacer;
  }

  .header {
    button {
      padding: 0 $spacer;

      svg {
        height: 26px;
        width: 26px;
      }
    }

    p {
      display: none;
    }
  }
}

@media all and (max-width: 350px) {
  .header button span {
    display: none;
  }
}
