@use "local_modules/bbr-bootstrap/theme/_variables.scss" as *;
@import "../../../../styles/_variables-custom.scss";
@import "../../Admin.module.scss";

.tilbage {
  svg {
    transform: rotate(180deg);
  }
}

.header {
  display: flex;
  align-items: flex-end;
  margin-bottom: $spacer;
  h2 {
    font-size: 1.5em;
    font-weight: 400;
    margin-right: $spacer;
  }
  h3 {
    font-size: 1.2em;
    font-weight: 400;
    margin-right: $spacer;
  }
}

.rolle {
  display: flex;
  margin: $spacer 0;
  span {
    font-size: 1.1em;
  }
}
.button {
  font-style: italic;
  font-weight: 600;
}
