/** 
 * BBR-Bootstrap settings
 * Custom settings til dette tema
 */

$custom-bbr-components: true; // Enable custom BBR Bootstrap components (deaktiveres dette er det ren variable stylet bootstrap kode)

/**
 * Bootstrap settings
 * (find mulige settings i boostrap .variables under node_modules>bootstrap>scss>_variables.scss)
 */

$primary: #003c16;
$secondary: #8ebba7;
// $warning: #fedc2b;
$warning: #f5d041;
$danger: #93000f;
$success: #008100;
$info: #114551;
$dark: #2f2f2f;
$light: #f0efed;
$black: black;
$white: white;

$theme-colors: (
  "primary": $primary,
  "secondary": $secondary,
  "warning": $warning,
  "danger": $danger,
  "success": $success,
  "info": $info,
  "dark": $dark,
  "light": $light,
  "black": $black,
  "white": $white,
);

$map-green: #008100;
$map-green-light: #00d200;
$map-lime: #d4eb3a;
$map-blue: #133f88;
$map-blue-light: #3098d8;
$map-red: #db0101;
$map-yellow: #ffd400;

$map-colors: (
  "map-green": $map-green,
  "map-green-light": $map-green-light,
  "map-lime": $map-lime,
  "map-blue": $map-blue,
  "map-blue-light": $map-blue-light,
  "map-red": $map-red,
  "map-yellow": $map-yellow,
);

$theme-colors: map-merge($theme-colors, $map-colors);

$color-mode-type: data;
$enable-dark-mode: false;

// Text colors
// $primary-text: shade-color($primary, 20%);
// $secondary-text: red;
// $success-text: shade-color($success, 20%);
// $info-text: shade-color($info, 40%);
// $warning-text: shade-color($warning, 40%);
// $light-text: shade-color($light, 20%);
// $dark-text: shade-color($dark, 40%);
// $danger-text: shade-color($danger, 20%);
// $text-muted: #728b7b;
$text-muted: #5e7365;

// Background Subtle colors
$primary-bg-subtle: tint-color($primary, 80%);
$secondary-bg-subtle: tint-color($secondary, 80%);
$success-bg-subtle: #d9f0c1;
$info-bg-subtle: #d8eaf2;
$warning-bg-subtle: tint-color(
  $warning,
  80%
); //#FCF5CA; oprindelige farve jf. design prøv med 80% først
$danger-bg-subtle: #ffbdbd;
$light-bg-subtle: tint-color($light, 40%);
$dark-bg-subtle: tint-color($danger, 20%);

// Border Subtle colors
$primary-border-subtle: tint-color($primary, 60%);
$secondary-border-subtle: tint-color($secondary, 60%);
$success-border-subtle: tint-color($success, 60%);
$info-border-subtle: tint-color($info, 60%);
$warning-border-subtle: tint-color($warning, 60%);
$danger-border-subtle: tint-color($danger, 60%);
$light-border-subtle: tint-color($light, 20%);
$dark-border-subtle: tint-color($dark, 60%);

// Body colors
$body-color: $dark;
$body-bg: $white;
$body-emphasis-color: $black;
$body-secondary-color: rgba($body-color, 0.75);
$body-secondary-bg: #f5f7f8;
$body-tertiary-color: rgba($body-color, 0.5);
$body-tertiary-bg: #f4f7f6;
$emphasis-color: $black;

/* ***** Font Sizes ***** */
// base
$font-family-base: "Open Sans", sans-serif, "Apple Color Emoji",
  "Segoe UI Emoji", "Segoe UI Symbol", "Noto Color Emoji";

// display font
$display-font-sizes: (
  1: 4rem,
  2: 3.5rem,
  3: 3rem,
  4: 2.5rem,
  5: 2rem,
  6: 1.5rem,
);

$display-font-weight: 600;

//headings
$headings-font-weight: 600;
$headings-color: $primary;
$headings-line-height: 1.3;

/* ***** Utilities ***** */

/* ***** General styling ***** */
// General
$enable-negative-margins: true;
$enable-cssgrid: true;

$border-radius: 0.25em;
$border-color: #D7E0DE;
$modal-content-border-color: $border-color;

//spacers
$spacer: 1rem;
$spacers: (
  0: 0,
  1: $spacer * 0.25,
  2: $spacer * 0.5,
  3: $spacer,
  4: $spacer * 1.5,
  5: $spacer * 3,
  6: $spacer * 4,
  7: $spacer * 5,
  8: $spacer * 6,
  9: $spacer * 7,
  10: $spacer * 8,
);

// General all form elements
$border-radius: 0.25rem;
$border-radius-sm: 0.25rem;
$border-radius-lg: 0.25rem;

// Button
$btn-padding-y: 0.375rem;
$btn-padding-x: 1rem;

// Badges
$badge-border-radius: 20em;
$badge-padding-y: 0.35em;
$badge-padding-x: 0.75em;
$badge-font-weight: 600;

// Modal
$modal-content-border-radius: 0;

// Alerts
$alert-border-radius: 0;
$alert-border-width: 0;

//Box-shadow
$box-shadow: 0 0.5rem 1.15rem rgba($black, 0.1);
$box-shadow-sm: 0 0.25rem 0.75rem rgba($black, 0.075);
$box-shadow-lg: 0 0.75rem 1.5rem rgba($black, 0.125);
