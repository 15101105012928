/* Custom alert style kode */
@if $custom-bbr-components {

// Subtle loop colors
@each $color, $value in $theme-colors {
    .alert-#{$color} {
        border-left: 4px solid $value;
    }
}


}