/* ********* BBR-Explore unikke variabler (custom) ********* */
// @use "local_modules/bbr-bootstrap/theme/_variables.scss" as *;
// Subtle colors
$secondary-bg-subtle: tint-color($secondary, 80%); // findes i BBR-bootstrap !!
$secondary-bg-200: tint-color($secondary, 75%);
$secondary-bg-300: tint-color($secondary, 70%);
$secondary-bg-400: tint-color($secondary, 60%);

$warning-bg-subtle: tint-color($warning, 60%);

$info-bg-100: tint-color($info, 93%);
$info-bg-200: tint-color($info, 90%);
$info-bg-subtle: tint-color($info, 80%); // findes i BBR-bootstrap !

// Text colors
$secondary-text: #728b7b; // findes i BBR-bootstrap !!
$secondary-text-darker: shade-color($secondary, 40%);

// Shadows
$drop-shadow-lg: drop-shadow(
  $box-shadow-lg
); // - panes as drop-shadow-filter only

// Buttons
$button-focus-color: $secondary-bg-200;

// Sizing
$navbar-height: $spacer * 5;
$subheader-height: $spacer * 4.7;
$pane-header-height: $spacer * 4.5;
$sidepane-width: $spacer * 23;

$break-laptop: 1400px;
$break-tablet-md: 1200px;
$break-tablet-sm: 900px;
$break-mobile: 767px;
$break-mobile-sm: 350px;

// Mixins
@mixin main-h2 {
  font-size: 1.7em;
  font-weight: 400;
  margin-bottom: 1.3 * $spacer;
}
