// @use "node_modules/bootstrap/scss/functions" as *;
@use "local_modules/bbr-bootstrap/theme/_variables.scss" as *;
@use "../../styles/_variables.scss" as *;
@import "../../styles/_variables-custom.scss";

.btn--icon {
  display: flex;
  align-items: center;
  justify-content: center;
  padding: 0;
  width: 2rem;
  height: 2rem;
  background: transparent;
  border-radius: 50rem;
  &:hover {
    background-color: darken($body-tertiary-bg, 5%);
  }
}

.btn--normal {
  display: flex;
  align-items: center;
  gap: 0.5rem;
  padding: 0 0.5rem 0 0.5rem;
  height: 100%;
  background: transparent;
  border-radius: 50rem 1rem 1rem 50rem;
  font-weight: 600;
  &:hover {
    background-color: darken($body-tertiary-bg, 5%);
  }
}

.btn--next {
  border-radius: 0.5rem 50rem 50rem 0.5rem;
}
