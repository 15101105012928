@use "sass:math";
@use "node_modules/bootstrap/scss/functions" as *;
@use "local_modules/bbr-bootstrap/theme/_variables.scss" as *;
@use "../../../styles/_variables.scss" as *;
@import "../../../styles/_variables-custom.scss";
.container {
  display: flex;
  flex-direction: column;
  overflow: hidden;
  flex: 0 0 auto;

  button {
    text-align: left;
  }
  ::-webkit-scrollbar-track {
    border-left: 1px solid $secondary-bg-subtle;
    // box-shadow: inset 1px 0 5px 0 $secondary-bg-subtle;
  }
}

.expanded {
  flex: 1 1 100%;
  border-bottom: 1px solid $border-color;
}

.accordionheader {
  flex: 0 0 auto;
  height: $pane-header-height;
  border-bottom: 1px solid $border-color;
  cursor: pointer;
  display: flex;
  align-items: center;
  padding: $spacer;
  font-size: 1.1em;
  font-weight: 600;
  color: $primary;
  position: relative;
  &:focus {
    background-color: $button-focus-color;
    box-shadow: none;
  }
  .headertext {
    padding: 0 0.5 * $spacer;
  }
  .expand {
    position: absolute;
    top: 50%;
    transform: translate3d(0, -50%, 0);
    right: $spacer;
  }
}

.opgaver {
  flex: 1 1 auto;
  overflow-y: auto;
}

.opgave {
  width: 100%;
  height: $spacer * 4.5;
  border-bottom: 1px solid $border-color;
  padding: $spacer;
  display: flex;
  align-items: center;
  transition: background-color 300ms;
  cursor: pointer;
  &:hover {
    background-color: $secondary-bg-subtle;
    p,
    .antal {
      color: $primary;
    }
  }
  &.selected {
    background-color: $secondary-bg-subtle;
    p,
    .antal {
      color: $primary;
    }
  }
  .antal {
    margin: 0 math.div(2, 3) * $spacer 0 auto;
    color: $secondary-text;
  }
  h2 {
    font-size: 1.1em;
    font-weight: 400;
    margin: 0;
  }
  p {
    max-width: $spacer * 16;
    white-space: nowrap;
    text-overflow: ellipsis;
    overflow: hidden;
    margin: 0;
    font-size: 0.8em;
    line-height: 150%;
    color: $secondary-text-darker;
  }
}

.opgave.loading {
  padding: 0 $spacer;
  @keyframes pulse {
    0% {
      background-color: $body-tertiary-bg;
    }
    50% {
      background-color: $secondary-bg-subtle;
    }
    100% {
      background-color: $secondary-bg-300;
    }
  }
  .prioritet {
    animation: pulse 1s infinite ease-in-out;
  }
  .text {
    flex: 1;
    display: flex;
    flex-direction: column;
    align-items: stretch;
    justify-content: space-evenly;
    margin: $spacer;
    .line {
      animation: pulse 1s infinite ease-in-out;
      height: 11px;
    }
  }
}
