@use "sass:math";
@use "node_modules/bootstrap/scss/functions" as *;
@use "local_modules/bbr-bootstrap/theme/_variables.scss" as *;
@use "../../../../../styles/_variables.scss" as *;
@import "../../../../../styles/_variables-custom.scss";

.enhed {
  box-sizing: border-box;
  flex: 1 1 24%;
  margin: 0.5%;
  padding: 3 * 0.5 * $spacer;
  background: $body-tertiary-bg !important;
  cursor: pointer;
  transition: 200ms;
  position: relative;
  // min-height: $spacer*8.5;

  &:hover {
    background: lighten($body-tertiary-bg, 2%) !important;
    .more {
      opacity: 1;
    }
  }

  &:focus {
    box-shadow: 0 0 0 1px inset $primary;
    background: $body-tertiary-bg !important;
  }

  // &.markeret {
  //   &.skal, &.kan {
  //     background: $warning !important;
  //   }
  // }
  &.enhed_th,
  &.enhed_mf,
  &.enhed_tv {
    flex: 1 1 30%;
  }

  &.enhed_unknown {
    flex: 0 1 24%;
    // Når færre end 4
    &:only-child,
    &:nth-child(1):nth-last-child(2),
    &:nth-child(2):nth-last-child(2),
    &:nth-child(2):nth-last-child(1),
    &:nth-child(1):nth-last-child(3),
    &:nth-child(3):nth-last-child(1) {
      flex-grow: 1;
    }
  }

  .label {
    padding-bottom: 0;
    margin-right: math.div(1, 3) * $spacer;
    font-weight: 600;
    color: $dark;
  }
  .label,
  span {
    display: inline-block;
  }

  .enhedItem {
    flex: 1 1 auto;
    min-height: 24px;
    line-height: 22px;
    vertical-align: top;
    margin: 0 3 * 0.5 * $spacer 0.3 * $spacer 0;

    p {
      font-size: 0.9em;
      margin-bottom: 0;
    }

    &.markeret {
      &.skal,
      &.kan {
        padding: 0 5px;
        background: $warning;
      }
    }
    &.stretched {
      flex-basis: 100%;
    }
    .ejerlejlighed {
      position: relative;
      span {
        flex: 1 0 auto;
        margin-left: 4px;
        padding-right: 10px;
      }
    }
  }
  h4 {
    font-size: 1em;
    font-weight: 400;
    color: $black;
    margin-bottom: 0.5 * $spacer;
  }
}

.more {
  position: absolute;
  right: math.div(2, 3) * $spacer;
  bottom: $spacer;
  opacity: 0;
}

@media screen and (max-width: 1300px) {
  .enhed {
    &.enhed_unknown {
      flex: 0 1 49%;
    }
  }
}

@media all and (max-width: $break-mobile) {
  .enhed {
    margin: 4px 2px;
    &.enhed_unknown,
    &.enhed_th,
    &.enhed_mf,
    &.enhed_tv {
      flex: 1 1 100%;
    }
  }
}
