// @use "node_modules/bootstrap/scss/functions" as *;
// @use "local_modules/bbr-bootstrap/theme/_variables.scss" as *;
// @use "../../../styles/_variables.scss" as *;
// @import "../../../styles/_variables-custom.scss";

// .container {
//   display: flex;
//   width: 100%;
//   max-width: $spacer * 70;
//   margin: 0 auto;
//   flex-direction: column;
//   align-items: flex-start;
// }

// .cards {
//   display: grid;
//   width: 100%;
//   // grid-template-columns: repeat(auto-fit, minmax($spacer*21, 1fr));
//   grid-template-columns: repeat(auto-fit, $spacer * 21);
//   grid-gap: 2 * $spacer;
// }
// .list {
//   display: flex;
//   flex-direction: column;
//   width: 100%;
// }

// .pagingbottom {
//   margin-top: $spacer;
//   > div {
//     display: inline-flex;
//   }
// }

.loading {
  padding: 0 $spacer 0 0;
  @keyframes pulse {
    0% {
      background-color: $body-tertiary-bg;
    }
    100% {
      background-color: $secondary-bg-subtle;
    }
  }
  .prioritet {
    animation: pulse 1s infinite ease-in-out;
  }
  .text {
    flex: 1;
    display: flex;
    flex-direction: column;
    align-items: stretch;
    justify-content: space-evenly;
    margin-bottom: $spacer;
    margin-top: 5px;
    .line {
      animation: pulse 1s infinite ease-in-out;
      height: $spacer;
    }
  }
}

//ANIMATED CHECKMARKS
//https://jsfiddle.net/omu27fvt/6/

.c-checkmark--animated .path {
  stroke-dasharray: 1000;
  stroke-dashoffset: 0;
}
.c-checkmark--animated .path.circle {
  -webkit-animation: dash 0.9s ease-in-out;
  animation: dash 0.9s ease-in-out;
}

.c-checkmark--animated .path.check {
  stroke-dashoffset: -100;
  -webkit-animation: dash-check 0.9s 0.35s ease-in-out forwards;
  animation: dash-check 0.9s 0.35s ease-in-out forwards;
}

@-webkit-keyframes dash {
  0% {
    stroke-dashoffset: 1000;
  }
  100% {
    stroke-dashoffset: 0;
  }
}
@keyframes dash {
  0% {
    stroke-dashoffset: 1000;
  }
  100% {
    stroke-dashoffset: 0;
  }
}
@-webkit-keyframes dash-check {
  0% {
    stroke-dashoffset: -100;
  }
  100% {
    stroke-dashoffset: 900;
  }
}
@keyframes dash-check {
  0% {
    stroke-dashoffset: -100;
  }
  100% {
    stroke-dashoffset: 900;
  }
}

.c-error--animated .path {
  stroke-dasharray: 1000;
  stroke-dashoffset: 0;
}
.c-error--animated .path.circle {
  -webkit-animation: dash 0.9s ease-in-out;
  animation: dash 0.9s ease-in-out;
}

.c-error--animated .path.line {
  stroke-dashoffset: 1000;
  -webkit-animation: dash 0.9s 0.35s ease-in-out forwards;
  animation: dash 0.9s 0.35s ease-in-out forwards;
}
