@use "sass:math";
@use "local_modules/bbr-bootstrap/theme/_variables.scss" as *;
@import "../../../../styles/_variables-custom.scss";
@import "../../Admin.module.scss";
.container {
  width: 100%;
  max-width: $spacer * 70;
  margin: 0 auto;
}
li {
  button {
    width: 100%;
  }
}

.listHeader {
  width: 100%;
  display: flex;
  justify-content: space-between;
  flex-direction: row;
  margin-top: $spacer;
  border-bottom: solid 1px $secondary-bg-300;
  position: relative;
  padding: $spacer;

  h2 {
    width: 100%;
    max-width: $spacer * 70;
    margin: 0 auto;
  }

  > div {
    margin-right: math.div(2, 3) * $spacer;
  }
  .navn {
    flex: 3 1 0px;
  }
  .beskrivelse {
    flex: 3 1 0px;
    text-align: justify;
  }
  .antal {
    flex: 1 1 0px;
  }
  .listetype {
    flex: 1 1 0px;
  }
  .status {
    flex: 1 1 0px;
  }
}

@media all and (max-width: $break-mobile) {
  .listHeader {
    display: none;
  }
}

.filters {
  border: solid 1px $primary-border-subtle;
  padding: $spacer;
  margin: $spacer 0;
}
