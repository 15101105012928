@use "local_modules/bbr-bootstrap/theme/_variables.scss" as *;
@use "../../styles/_variables.scss" as *;
@import "../../styles/_variables-custom.scss";
.container {
  width: 100%;
  max-width: $spacer * 70;
  margin: 0 auto;
  h1 {
    font-size: 1.8rem;
    margin-bottom: 2.4rem;
    font-weight: normal;
  }
  h2 {
    font-size: 1.4rem;
    font-weight: normal;
    border-right: 2rem solid transparent;
  }
}

.placeholder {
  font-size: 1.75rem;
  color: $secondary-text;
  width: 80%;
  margin: 6rem auto;
  .arrow {
    display: inline-block;
    transform: rotate(-140deg);
  }

  p {
    margin-top: $spacer;
  }
}
.statcontainer {
  background: white;
  padding: $spacer * 2;
  margin-bottom: 1.7rem;
}

.overblik {
  display: flex;
  justify-content: space-evenly;
  > li {
    display: flex;
    flex-direction: column;
    align-items: center;
  }
}

.headerrow {
  display: flex;
  align-items: center;
  justify-content: space-between;
  color: $primary;
  > * {
    flex: 1 1 33%;
  }
  > div {
    display: flex;
    justify-content: center;
    align-items: center;
  }
}

.togglebutton {
  width: 150px;
  min-height: 35px;
  padding: 0.3rem 1rem;
  border: solid 1px $secondary-bg-300;
  color: #5c7a67;
  transition: opacity 300ms ease;
  white-space: nowrap;
}
.togglebutton.active {
  opacity: 1;
  border: solid 1px $primary;
  color: $primary;
}

.vis {
  margin-right: 1rem;
}

.loading {
  @keyframes pulse {
    0% {
      background-color: $body-tertiary-bg;
    }
    100% {
      background-color: $white;
    }
  }
  animation: pulse 1s infinite ease-in-out;
}

@media screen and (max-width: $break-tablet-md) {
  .headerrow {
    > * {
      flex: 0 1 auto;
    }
    > div:last-child {
      display: none;
    }
  }
}

@media screen and (max-width: $break-mobile) {
  .headerrow {
    flex-wrap: wrap;
    > * {
      flex: 1 1 100%;
    }
    > div:last-child {
      display: none;
    }
  }

  .container {
    h2 {
      border: none;
      margin-bottom: 1rem;
    }
  }

  .overblik {
    flex-direction: column;
    > li:not(:last-child) {
      margin-bottom: 2rem;
    }
  }

  .togglebutton {
    width: 50%;
    font-size: 0.9em;
  }

  .vis {
    display: none;
  }
}
