@use "local_modules/bbr-bootstrap/theme/_variables.scss" as *;
@use "../../styles/_variables.scss" as *;

.container {
  position: relative;
  margin-bottom: 1.5rem;
}

.content {
  overflow: hidden;
  transition: max-height 0.3s ease-out;
  line-height: 1.55rem;
}

.expanded {
  max-height: 5000px;
  transition: max-height 0.3s ease-out;
}

.btn {
  padding: 0;
  padding-top: 0.5rem;
  font-size: 0.95rem;
  font-weight: 600;
  border: unset;
  outline: none;
  // box-shadow: unset !important;
}

.overlay {
  position: absolute;
  bottom: 0;
  left: 0;
  right: 0;
  height: 40%;
  background: linear-gradient(
    to bottom,
    transparent 0%,
    $body-secondary-bg 40%
  );
  display: flex;
  align-items: flex-end;
}
