.titleContainer {
  display: flex;
  flex-direction: column;
  overflow: hidden;
  flex: 1 0 auto;

  h3 {
    span {
      float: right;
    }
  }
}
