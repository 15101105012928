// @use 'node_modules/bootstrap/scss/functions' as *;
@use "local_modules/bbr-bootstrap/theme/_variables.scss" as *;
@use "../../../styles/_variables.scss" as *;
@import "../../../styles/_variables-custom.scss";

.listitem {
  border-bottom: 1px solid $secondary;
}

.artikellistitem {
  padding: $spacer;
  display: flex;
  align-items: center;
  font-weight: 400;
  color: inherit;
  transition: 200ms;
  justify-content: space-between;
  &:hover {
    background-color: #fff;
    svg {
      margin-right: 0;
    }
  }

  .main {
    width: 90%;
  }

  h2 {
    color: $info;
    font-size: 1.4em;
    font-weight: 400;
  }

  p {
    margin: 0.3 * $spacer 0;
    width: 100%;
    overflow: hidden;
    text-overflow: ellipsis;
    white-space: nowrap;
  }

  small {
    color: $info-bg-subtle;
    font-size: 0.9em;
    margin-right: 1rem;
  }

  svg {
    color: $info;
    margin-right: 5px;
    transition: margin-right 200ms;
  }
}
