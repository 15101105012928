@use "node_modules/bootstrap/scss/functions" as *;
@use "local_modules/bbr-bootstrap/theme/_variables.scss" as *;
@use "../../../../../styles/_variables.scss" as *;
@import "../../../../../styles/_variables-custom.scss";

.container {
  display: flex;
  flex-wrap: wrap;
  width: 100%;

  background-color: darken($secondary-bg-subtle, 5%);
  margin-bottom: 4 * 0.2 * $spacer;
  padding: 0.5 * $spacer;

  &.last {
    padding-bottom: 12 * $spacer;
  }
}
