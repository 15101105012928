@use "local_modules/bbr-bootstrap/theme/_variables.scss" as *;
@use "../../../styles/_variables.scss" as *;
@import "../../../styles/_variables-custom.scss";
.container {
  // padding-top: $navbar-height;
  padding-top: 73px;
  height: 100vh;
  overflow: hidden;
  display: flex;
  z-index: 1;
  position: relative;
}

.sidebar {
  background-color: $white;
  overflow: hidden;
  filter: $drop-shadow-lg;
  width: $sidepane-width;
  transition: width 300ms;
  height: 100%;
  position: relative;
}
.sidebarcontent {
  width: $sidepane-width;
  height: 100%;
  overflow: hidden;
}

.main {
  background-color: $body-tertiary-bg;
  overflow-y: scroll;
  flex: 1;
}

.overlay,
.showsidebar {
  display: none;
}

@media all and (max-width: $break-tablet-sm) {
  .sidebar {
    width: 60px;
    position: absolute;
    left: 0;
    top: $navbar-height;
    z-index: 10;
    height: calc(100vh - #{$navbar-height});
  }

  .hidesidebar {
    width: 60px;
    height: 60px;
    position: absolute;
    top: 0;
    color: #fff;
    left: 0;
    opacity: 0;
  }

  .showsidebar {
    display: block;
    background: #fff;
    position: absolute;
    z-index: 10;
    top: 1px;
    left: 0;
    width: 100%;
    height: 100%;
    display: flex;
    span {
      color: $primary;
      padding: 1.1 * $spacer 0;
      width: 100%;
      height: $pane-header-height;
    }
  }

  .sidebarcontent {
    visibility: hidden;
  }

  .overlay {
    position: absolute;
    height: 100%;
    width: 100%;
    z-index: 9;
    background-color: rgba(0, 0, 0, 0.3);
    display: block;
    visibility: hidden;
    opacity: 0;
    transition: visibility 0s 0.3s, opacity 0.3s 0s ease;
  }

  // .sidebar:hover,
  .sidebar.opensidebar {
    width: $sidepane-width;
    max-width: 80vw;
    .showsidebar {
      display: none;
    }
    ~ .overlay {
      visibility: visible;
      opacity: 1;
      transition: opacity 0.3s ease;
      .hidesidebar {
        left: $sidepane-width;
        opacity: 1;
        transition: opacity 0.5s 0.3s;
      }
    }
    .sidebarcontent {
      visibility: visible;
      max-width: 80vw;
    }
  }

  .main {
    margin-left: 60px;
  }
}

@media all and (max-width: $break-mobile-sm) {
  .sidebar:hover ~ .overlay .hidesidebar {
    left: 80vw;
  }
}
