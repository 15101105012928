@use "sass:math";
@use "node_modules/bootstrap/scss/functions" as *;
@use "local_modules/bbr-bootstrap/theme/_variables.scss" as *;
@use "../../styles/_variables.scss" as *;
@import "../../styles/_variables-custom.scss";

// .container {
//   padding: $navbar-height 0 0 0;
//   height: 100%;
//   width: 100%;
//   > div,
//   footer {
//     width: 100%;
//     padding: 0 4 * $spacer;
//   }
//   h2 {
//     @include main-h2;
//     > * {
//       display: inline-block;
//       vertical-align: middle;
//     }
//     svg {
//       margin-right: $spacer;
//     }
//   }
// }
.formlink {
  padding: 1rem;
  text-align: start;
  background-color: $light;
  appearance: none;
  font-size: 15px;
  border: none;
  cursor: pointer;
  transition: background-color 0.3s;
  &:hover {
    background-color: $secondary-bg-300;
  }
}

// .grid {
//   width: 910px;
//   padding: $spacer * 6 0 $spacer * 6 0;
//   margin: 0 auto;
// }

// .intro {
//   > div {
//     flex: 1 1;
//   }
//   .col1 {
//     width: 70%;
//   }

//   .col2 {
//     width: 30%;
//   }
//   h1 {
//     font-size: 3rem;
//     margin-bottom: 0.5em;
//     color: $secondary;
//     line-height: 130%;
//   }
//   p {
//     margin-bottom: 1.5rem;
//     margin-right: 2rem;
//     line-height: 1.6;
//   }
//   .grid {
//     padding-bottom: 2.5 * $spacer;
//     display: flex;
//   }
// }

// .ribbon {
//   background-color: rgba($secondary, 0.6);
//   color: $primary;
//   p {
//     font-size: 1.2rem;
//     line-height: 145%;
//   }
//   .grid {
//     display: flex;
//     justify-content: space-between;
//     align-items: center;
//     > div {
//       flex-basis: 50%;
//     }
//   }
// }

// .searchbox {
//   background-color: $body-tertiary-bg;
// }

// .artikler {
//   background-color: $body-tertiary-bg;
//   .header {
//     display: flex;
//     justify-content: space-between;
//     align-items: center;
//     padding-bottom: 2 * $spacer;
//   }
//   h2 {
//     margin: 0;
//   }

//   ul {
//     margin-bottom: $spacer;
//   }

//   .all {
//     padding: math.div(3, 2) * $spacer 0;
//     display: flex;
//     justify-content: flex-end;
//   }
// }

.listitem {
  // border-bottom: 1px solid $secondary;
  // &:first-child {
  //   border-top: 1px solid $secondary;
  // }
  &.loading {
    padding: 0 $spacer 0 0;
    @keyframes pulse {
      0% {
        background-color: $secondary-bg-subtle;
      }
      100% {
        background-color: $secondary-bg-200;
      }
    }
    .prioritet {
      animation: pulse 1s infinite ease-in-out;
    }
    .text {
      flex: 1;
      display: flex;
      flex-direction: column;
      align-items: stretch;
      justify-content: space-evenly;
      margin-bottom: $spacer;
      margin-top: 5px;
      .line {
        animation: pulse 1s infinite ease-in-out;
        height: $spacer;
      }
    }
  }
}

// .artikellistitem {
//   padding: $spacer;
//   display: flex;
//   align-items: center;
//   font-weight: 400;
//   color: inherit;
//   transition: 200ms;
//   justify-content: space-between;
//   &:hover {
//     background-color: #fff;
//     svg {
//       margin-right: 0;
//     }
//   }

//   .main {
//     width: 90%;
//   }

//   h2 {
//     color: $info;
//     font-size: 1.4em;
//     font-weight: 400;
//   }

//   p {
//     margin: 0.3 * $spacer 0;
//     width: 100%;
//     overflow: hidden;
//     text-overflow: ellipsis;
//     white-space: nowrap;
//   }

//   small {
//     color: $text-muted;
//     font-size: 0.9em;
//     margin-right: 1rem;
//   }

//   svg {
//     color: $info;
//     margin-right: 5px;
//     transition: margin-right 200ms;
//   }
// }

// .links {
// display: flex;
// .linksarrow {
//   li {
//     margin-top: 3 * 0.5 * $spacer;
//   }
//   a {
//     color: $text-muted;
//     font-weight: 700;
//     &:hover {
//       opacity: 0.8;
//     }
//   }
// }
// .linksnoarrow {
//   li {
//     margin-top: 3 * 0.5 * $spacer;
//     margin-right: 5 * $spacer;
//   }
//   a {
//     color: $text-muted;
//     font-weight: 700;
//     &:hover {
//       opacity: 0.8;
//     }
//   }
// }
// }

// @media all and (max-width: $break-tablet-md) {
//   .grid {
//     width: 100%;
//   }
// }

// @media all and (max-width: $break-mobile) {
//   .container {
//     > div,
//     footer {
//       padding: 0 2 * $spacer;
//     }
//   }

//   .grid {
//     padding: 3 * $spacer 0;
//   }
//   .intro {
//     .col1 {
//       width: 100%;
//     }
//     .col2 {
//       display: none;
//     }
//   }

//   .ribbon .grid {
//     flex-direction: column;
//     button {
//       margin-top: 3 * $spacer;
//     }
//   }

//   .links,
//   .logos {
//     flex-wrap: wrap;
//   }
// }
