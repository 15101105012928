@use "sass:math";

// html, body {

// }

//undesøg bug slår ikke igennem ud fra variabler
.text-muted {
  color: $text-muted !important;
}
