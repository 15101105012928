.c-sticky-header {
  position: sticky;
  top: 5rem;
  margin-left: -4px;
  z-index: 1000;
  cursor: default;
  width: calc(100% + 8px);
  padding-top: 1rem;
}
// .c-divider {
//   width: 1px;
//   height: 100%;
//   border-left: 1px solid $text-muted;
// }
.divider--show {
  display: block;
}
// .c-statusdropdown-item {
//   display: flex;
// }
// .c-statusdropdown-item--mobile {
//   display: none;
// }

// @media screen and (max-width: 1000px) {
//   .c-sticky-header {
//     .c-sticky-header-content {
//       padding: 0.5rem 0;
//       .c-sticky-header-heading {
//         margin-bottom: 1.2rem;
//       }
//     }
//   }
// }
@media screen and (max-width: 1050px) {
  .divider--show {
    display: none;
  }
  // .c-statusdropdown-item {
  //   display: none;
  // }
  // .c-statusdropdown-item--mobile {
  //   display: block;
  // }
}

//navigation
@media all and (max-width: $break-tablet-sm) {
  // .links {
  //   display: none;
  // }
  // .c-nav-search {
  //   & .ask-component {
  //     width: 80%;
  //   }
  // }
}
@media all and (min-width: ($break-tablet-sm + 1px)) {
  // .c-mobile-nav,
  // div.c-mobile-nav {
  //   display: none;
  // }
}

@media all and (max-width: $break-mobile) {
  // .linkscontainer {
  //   margin-left: 0;
  // }

  // .c-mobile-nav {
  //   margin: 0 1rem;
  // }

  // .hamburgermenu {
  //   margin-top: 1.5rem !important;
  //   margin-right: -50vw;
  //   width: 100vw;
  //   border-left-color: transparent !important;
  //   border-right-color: none !important;
  //   border-top-color: $secondary !important;
  //   border-bottom-color: $secondary !important;
  // }
}
