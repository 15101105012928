@use "node_modules/bootstrap/scss/functions" as *;
@use "local_modules/bbr-bootstrap/theme/_variables.scss" as *;
@import "../../../../styles/_variables-custom.scss";

.loading {
  padding: 0 $spacer 0 0;
  @keyframes pulse {
    0% {
      background-color: $body-tertiary-bg;
    }
    100% {
      background-color: $secondary-bg-subtle;
    }
  }
  .prioritet {
    animation: pulse 1s infinite ease-in-out;
  }

  .text {
    flex: 1;
    display: flex;
    flex-direction: column;
    align-items: stretch;
    justify-content: space-evenly;
    margin-bottom: 0.5rem;
    margin-top: 2px;
    .line {
      animation: pulse 1s infinite ease-in-out;
      height: $spacer;
    }
  }
}

.foldheading {
  padding: 0;
}

.sammeejere {
  display: flex;
  flex-wrap: wrap;
  align-items: stretch;
  margin: 0.5rem 0 0.2rem 0;
  > li {
    flex: 0 0 50%;
  }

  a {
    font-size: 0.95em;
    padding: 0.7em 35px 0.7em 0.7em;
    margin: 0 0.4em 0.4em 0;
    background: $body-tertiary-bg;
    transition: 0.2s;
    display: block;
    position: relative;
    line-height: 130%;
    &:hover {
      background: $secondary-bg-subtle;
      svg {
        right: 9px;
        opacity: 1;
      }
    }

    &.sammebfe {
      background: $secondary-bg-subtle;
    }

    svg {
      position: absolute;
      right: 12px;
      top: 50%;
      transform: translateY(-50%);
      transition: 0.2s;
      opacity: 0.5;
    }
  }
}

.ejer {
  display: flex;
  flex-direction: column;
  margin-bottom: 0;
}

.ejerforhold {
  &:empty {
    display: none;
  }
}

.fejl {
  p {
    color: $text-muted;
  }
}
