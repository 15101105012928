@use "local_modules/bbr-bootstrap/theme/_variables.scss" as *;
@import "../../../../styles/_variables-custom.scss";
@import "../../Admin.module.scss";
.listHeader,
.listrow {
  .navn {
    flex: 2 1 0px;
  }
  .cvr {
    flex: 1 1 0px;
  }
  .email {
    flex: 2 1 0px;
  }
}
.listHeader {
  margin-top: $spacer;
}
.listrow {
  border-bottom: solid 1px tint-color($text-muted, 40%);
}
.listrow:hover {
  background-color: $white;
}

@media all and (max-width: $break-mobile) {
  .listHeader {
    display: none;
  }

  .listrow {
    flex-direction: column;
  }
}
