@use "node_modules/bootstrap/scss/functions" as *;
@use "local_modules/bbr-bootstrap/theme/_variables.scss" as *;
@use "../../../styles/_variables.scss" as *;
@import "../../../styles/_variables-custom.scss";

.container,
.buildingNestingContainer {
  position: relative;
}
.container::after {
  position: absolute;
  bottom: -7px;
  content: "";
  display: block;
  border-left: 1px solid $secondary-bg-400;
  height: 100%;
  left: 6px;
}
.container:last-child {
  &:after {
    display: none;
  }
}

.buildingNestingContainer::after {
  position: absolute;
  top: -$spacer;
  content: "";
  display: block;
  border-left: 1px solid $secondary-bg-400;
  height: calc(100% + #{$spacer});
  left: calc(#{$spacer} + 6px);
}

.hidden {
  display: none;
}
