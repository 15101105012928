@use "sass:math";
@use "node_modules/bootstrap/scss/functions" as *;
@use "local_modules/bbr-bootstrap/theme/_variables.scss" as *;
@use "../../../../../styles/_variables.scss" as *;
@import "../../../../../styles/_variables-custom.scss";
.container {
  .opgangContainer {
    display: flex;
    flex-wrap: wrap;
    justify-content: left;
    .item > h4 {
      color: $black;
      margin: 3 * 0.5 * $spacer 0 2 * $spacer 10px;
      font-weight: 400;
      font-size: 1.07em;
      b {
        font-weight: 600;
      }
    }
  }

  .item,
  .enheder {
    width: 100%;
  }

  .enheder {
    width: 100%;
    position: relative;
    border-right: 4px dashed $secondary-bg-200;
    border-left: 4px dashed $secondary-bg-200;
    padding: 0 math.div(2, 3) * $spacer;
    margin: $spacer 0;

    &:before {
      // tag
      content: "";
      width: 100%;
      border-style: solid;
      border-color: transparent;
      border-width: 0 0 50px 0;
      border-bottom-color: $secondary-bg-subtle;
      margin: 0 0 math.div(2, 3) * $spacer 0;
    }
  }

  .item:first-child:last-child .enheder {
    // en enkelt opgang
    border: none;
    &:before {
      border-width: 0 50px 50px 50px;
    }
  }

  .item:first-child .enheder {
    // første opgang
    border-left: 0;
    border-right: 4px dashed $secondary-bg-200;
    &:before {
      border-width: 0 0 50px 50px;
    }
  }

  .item:last-child .enheder {
    // siste opgang
    border-right: 0;
    border-left: 4px dashed $secondary-bg-200;
    &:before {
      border-width: 0 50px 50px 0;
    }
  }

  .item > div {
    // supplerende oplysninger div
    margin: 0 0.5 * $spacer 3 * 0.5 * $spacer 0.5 * $spacer;
  }
}
