@use "sass:math";
@use "local_modules/bbr-bootstrap/theme/_variables.scss" as *;
@use "../../styles/_variables.scss" as *;
@import "../../styles/_variables-custom.scss";
.container {
  position: fixed;
  z-index: 400;
  top: 50%;
  left: 50%;
  transform: translate3d(-50%, -50%, 0);
  color: $primary;
}

.modalcont {
  display: flex;
  flex-direction: column;
}

.close {
  align-self: flex-end;
  color: #fff;
  margin-bottom: $spacer;
  font-size: 1.4em;
  font-weight: 600;
  display: flex;
  align-items: center;
  cursor: pointer;
  span {
    margin-left: math.div(2, 3) * $spacer;
  }
}

.content {
  background-color: $white;
  border-radius: 8px;
  padding: 1px;
  box-shadow: 0 0 15px rgba(0, 0, 0, 0.2);
}

.backdrop {
  z-index: 1300;
  position: fixed;
  top: 0;
  left: 0;
  bottom: 0;
  right: 0;
  background-color: rgba(0, 0, 0, 0.6);
}
