@use "sass:math";
@use "local_modules/bbr-bootstrap/theme/_variables.scss" as *;
@import "../../../../styles/_variables-custom.scss";
@import "../../Admin.module.scss";

.listHeader {
  margin-top: $spacer;
}
.listHeader,
.listrow {
  > div {
    margin-right: math.div(2, 3) * $spacer;
  }
  .navn {
    flex: 3 1 0px;
  }
  .org {
    flex: 3 1 0px;
    text-align: justify;
  }
  .cvr {
    flex: 2 1 0px;
  }
  .roller {
    flex: 1 1 0px;
  }
}

.listrow {
  border-bottom: solid 1px tint-color($text-muted, 40%);
}
.listrow:hover {
  background-color: $white;
}

@media all and (max-width: $break-mobile) {
  .listrow {
    flex-direction: column;
    .cvr,
    .roller {
      display: none;
    }
  }
  .listHeader {
    display: none;
  }
}

// .filters {
//   border: solid 1px $primary-border-subtle;
//   padding: $spacer;
//   margin: $spacer 0;
//   label {
//     font-weight: 700;
//     color: $primary;
//     font-size: 0.8rem;
//   }
//   .filtermenuitem {
//     padding: 0;
//     height: 3rem;
//     transition: background-color 300ms;
//     background-color: $light-bg-subtle;
//     .statuslabel {
//       display: flex;
//       align-items: center;
//       input {
//         margin: 1rem;
//       }
//     }
//     .check {
//       position: absolute;
//       left: math.div(2, 3) * $spacer;
//     }
//     .sortarrow {
//       position: absolute;
//       right: math.div(2, 3) * $spacer;
//     }
//     &:hover {
//       // background-color: $green-300;
//     }
//   }
//   .filtrer,
//   .sorter {
//     cursor: pointer;
//     height: 100%;
//     display: flex;
//     align-items: center;
//     padding: 0 $spacer;
//     background-color: rgba(#fff, 0.5);
//     // color: $green-900;
//     border: 1px solid $light-border-subtle;
//     transition: all 200ms;
//     &:hover {
//       border: 1px solid $secondary-border-subtle;
//       background-color: rgba(#fff, 1);
//     }
//     span {
//       font-size: 1rem;
//     }
//   }
//   .filtrer {
//     min-width: 252px;
//     height: 3rem;
//     b {
//       font-weight: 600;
//     }
//     > * {
//       margin-right: 0.5 * $spacer;
//     }
//     .chevron {
//       margin-left: auto;
//     }
//   }
//   .filtermenu {
//     left: 0;
//   }
// }
