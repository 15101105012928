$searchbox-height: 55px;

.ask-component {
  border: 2px solid $primary;
  border-radius: 8px;
  padding-top: $searchbox-height;
  font: inherit;
  font-size: 1rem;
  &.is-active {
    box-shadow: 0 0 0 3px rgba($secondary, 0.6);
    .has-shadow {
      box-shadow: 0 0.125rem 1rem rgba(0, 0, 0, 0.075);
    }
  }
  button:focus {
    box-shadow: unset;
  }
}

.ask-searchbox {
  height: $searchbox-height;
  border-radius: 8px;
}

.has-shadow {
  box-shadow: unset;
}

.ask-searchbox-input {
  input {
    font-family: inherit;
    font-size: inherit;
    height: $searchbox-height;
    &:focus {
      box-shadow: unset !important;
      outline: none;
    }
    &::-webkit-input-placeholder {
      font-weight: 500;
      color: $text-muted;
    }
    &:-ms-input-placeholder {
      font-weight: 500;
      color: $text-muted;
    }
    &::placeholder {
      font-weight: 500;
      color: $text-muted;
    }
  }
}

.ask-list-item {
  height: 50px;
}

.ask-list-item__action i,
.ask-list-item__action svg,
.ask-list-item__avatar i,
.ask-list-item__avatar svg {
  fill: $primary;
  color: $primary;
}

.ask-list-title,
.ask-title {
  font-size: 1rem;
  line-height: 1.7rem;
}

.ask-badge {
  background-color: $primary;
  color: #fff;
  font-size: 0.85rem;
  padding: 0.3em;
  > button {
    font-size: 1.2rem;
    svg {
      padding: 0.15em;
    }
  }
}

.ask-searchbox-action__item svg {
  fill: $primary;
}

.ask-searchbox-action__item--clear {
  svg {
    fill: rgba($primary, 0.5);
  }
  &:hover svg {
    fill: $primary;
  }
}

.ask-btn:hover:not([disabled="disabled"]):not(.ask-btn--text) {
  background-color: $body-tertiary-bg;
}

.iconname-is-default .ask-list-item__action svg,
.iconname-is-default .ask-searchbox-action__item--clear svg {
  height: 1.1em;
}

//navigation search
.c-nav-search {
  $searchbox-height: 40px;

  & .ask-component {
    border: 1px solid $border-color !important;
    border-radius: 8px;
    padding-top: $searchbox-height;
    font: inherit;
    font-size: 0.875rem;
    &.is-active {
      box-shadow: 0 0 0 2px rgba($secondary, 0.6);
      .has-shadow {
        box-shadow: 0 0.125rem 1rem rgba(0, 0, 0, 0.075);
      }
    }
    button:focus {
      box-shadow: unset;
    }
  }

  .ask-searchbox {
    height: $searchbox-height;
    border-radius: 8px;
  }

  .has-shadow {
    box-shadow: unset;
  }

  .ask-searchbox-input {
    input {
      font-family: inherit;
      font-size: inherit;
      height: $searchbox-height;
      &:focus {
        box-shadow: unset !important;
        outline: none;
      }
    }
  }

  .ask-list-item {
    height: 50px;
  }

  .ask-list-item__action i,
  .ask-list-item__action svg,
  .ask-list-item__avatar i,
  .ask-list-item__avatar svg {
    fill: $primary;
    color: $primary;
  }

  .ask-list-title,
  .ask-title {
    font-size: 1rem;
    line-height: 1.7rem;
  }

  .ask-badge {
    background-color: $primary;
    color: #fff;
    font-size: 0.85rem;
    padding: 0.3em;
    > button {
      font-size: 1.2rem;
      svg {
        padding: 0.15em;
      }
    }
  }

  .ask-searchbox-action__item svg {
    fill: $primary;
  }

  .ask-searchbox-action__item--clear {
    svg {
      fill: rgba($primary, 0.5);
    }
    &:hover svg {
      fill: $primary;
    }
  }

  .ask-btn:hover:not([disabled="disabled"]):not(.ask-btn--text) {
    background-color: $body-tertiary-bg;
  }

  .iconname-is-default .ask-list-item__action svg,
  .iconname-is-default .ask-searchbox-action__item--clear svg {
    height: 1.1em;
  }
}
