// @use 'node_modules/bootstrap/scss/functions' as *;
@use "local_modules/bbr-bootstrap/theme/_variables.scss" as *;
@use "../../../styles/_variables.scss" as *;
@import "../../../styles/_variables-custom.scss";

.warning {
  display: flex;
  align-items: center;
  height: 28px;
  font-size: 0.85rem;
  font-weight: 600;
  color: #000;
  position: relative;
  &:before {
    position: absolute;
    top: 13px;
    left: -10px;
    border: 4px solid transparent;
    border-top-color: #000;
  }
  > i {
    width: 20px;
    display: flex;
    align-items: center;
    justify-content: center;
  }
  &__text {
    margin-left: 0.35rem;
    display: none;
    &:hover {
      ~ .tooltip {
        display: block;
      }
    }
  }

  .tooltip {
    display: none;
    position: absolute;
    text-align: left;
    top: 35px;
    right: 0;
    width: 200px;
    background: #fff;
    box-shadow: $box-shadow;
    padding: 0.8 * $spacer;
    h5 {
      font-size: 0.8rem;
    }
    p {
      color: darken(#9b9898, 10);
    }
  }
  // &:hover {
  //   .tooltip {
  //     display: block;
  //   }
  // }
}

@media all and (max-width: $break-mobile) {
  .label {
    width: 110px;
  }
}
