@use "local_modules/bbr-bootstrap/theme/_variables.scss" as *;
@use "../../styles/_variables.scss" as *;
@import "../../styles/_variables-custom.scss";
.container {
  display: flex;
  width: 100%;
  max-width: $spacer * 70;
  margin: 0 auto;
  flex-direction: column;
  align-items: flex-start;
  padding: $spacer * 3;
  overflow: hidden;
  position: relative;
  h1 {
    font-size: 1.8rem;
    font-weight: normal;
  }
}
.placeholder_content {
}
.placeholder {
  font-size: 1.75rem;
  color: $secondary-text;
  width: 80%;
  margin: 6rem auto;
  .arrow {
    display: inline-block;
    transform: rotate(-140deg);
  }

  p {
    margin-top: $spacer;
  }
}
@media all and (max-width: $break-mobile) {
  .container {
    padding: 3 * 0.5 * $spacer $spacer;
  }
}
