.c-avatar {
  height: 2.5rem;
  width: 2.5rem;
  border-radius: 50%;
  display: flex;
  justify-content: center;
  align-items: center;
  text-align: center;
  font-weight: 600;
  font-size: 1rem;

  &--sm {
    height: 1.875rem;
    width: 1.875rem;
    font-size: 0.75rem;
  }

  &--xs {
    height: 24px;
    width: fit-content;
    max-height: 24px;
    max-width: fit-content;
    border-radius: 1000px;
  }
}
