@use "sass:math";
@use "node_modules/bootstrap/scss/functions" as *;
@use "local_modules/bbr-bootstrap/theme/_variables.scss" as *;
@import "../../../../styles/_variables-custom.scss";
@import "../../Admin.module.scss";
.container {
  width: 100%;
  max-width: $spacer * 70;
  margin: 0 auto;

  .indhold {
    input {
      height: 100%;
      width: 100%;
    }
  }
  // .selectboxwithbutton {
  //   display: flex;
  //   span {
  //     margin-left: 2 * $spacer;
  //   }
  // }

  .indhold {
    margin-top: 2rem;
  }
}

.addartikeltema {
  button {
    color: $secondary-text;
    font-size: 1.1rem;
    padding: 0.5 * $spacer $spacer;
    display: flex;
    align-items: center;
    span {
      margin-left: 0.5rem;
    }
    &:hover {
      color: $primary;
    }
  }
}
.selectboxwithbutton {
  select {
    margin-bottom: 0.5rem;
  }
}

.artikeltemaform {
  padding: math.div(2, 3) * $spacer $spacer;

  .artikeltemacontainer {
    display: flex;
    input {
      margin-left: $spacer;
      margin-bottom: 0;
      flex-basis: 80%;
    }
    .artikeltemasubmit {
      background-color: $secondary-bg-subtle;
      border: solid 1px $primary;
      margin-left: $spacer;
      margin-bottom: 0;
      color: $primary;
      width: 15%;
      min-width: 100px;
      font-weight: 600;
      height: 4 * $spacer;
      &:hover {
        background-color: $primary;
        color: white;
      }
    }
  }
}

.flexbetween {
  display: flex;
  align-items: flex-end;
  justify-content: space-between;
  fieldset {
    flex: 0 1 auto;
  }
}

@media all and (max-width: $break-mobile) {
  .artikeltemaform .artikeltemacontainer {
    flex-wrap: wrap;
    input,
    .artikeltemasubmit {
      flex-basis: 100%;
    }
    .artikeltemasubmit {
      margin-top: $spacer;
    }
  }
}
