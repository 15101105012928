input[type="checkbox"] {
  width: 1.15em;
  height: 1.15em;
  margin-top: 0.25em;
  vertical-align: top;
  background-color: #fff;
  background-repeat: no-repeat;
  background-position: center;
  background-size: contain;
  border: 1px solid rgba(0, 0, 0, 0.25);
  // border-radius: 4px;
  -webkit-appearance: none;
  -moz-appearance: none;
  appearance: none;
  -webkit-print-color-adjust: exact;
  print-color-adjust: exact;
  border-color: rgba($info, 0.75);

  &:checked {
    background-color: $info;
    border-color: $info;
    background-image: url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 20 20'%3e%3cpath fill='none' stroke='%23fff' stroke-linecap='round' stroke-linejoin='round' stroke-width='3' d='M6 10l3 3l6-6'/%3e%3c/svg%3e");
  }

  &:focus {
    // box-shadow: none;
    box-shadow: 0 0 0 0.25rem rgba($info, 0.4);
  }
}
