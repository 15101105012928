// tooltip when aria-label !
.btn--icon[aria-label],
a[aria-label],
.has-tooltip[aria-label] {
  position: relative;
  transition: opacity 0.3s;
  z-index: 0;

  &:after {
    content: attr(aria-label);
    white-space: nowrap;
    position: absolute;
    bottom: calc(100% + 7px);
    left: 50%;
    color: $white;
    background: $dark;
    padding: 0.4rem 0.5rem;
    z-index: 9999;
    border-radius: $border-radius;
    font-size: 0.75rem;
    line-height: 1;
    pointer-events: none;
    height: 0;
    visibility: hidden;
    opacity: 0;
    transition: opacity 0.3s;
    font-weight: 500;
    //   transition-delay: 0.3s;
  }
  &:hover:after {
    height: auto;
    visibility: visible;
    opacity: 1;
  }
  &.tooltip-end:after {
    right: 0;
  }
  &.tooltip-bottom:after {
    bottom: auto;
    top: calc(100% + 3px);
  }
  &.tooltip-start:after {
    left: 0;
  }
  &.tooltip-center:after {
    transform: translateX(-50%);
  }
  &.tooltip--fixed-width:after {
    white-space: normal;
    width: 200px;
    line-height: 1.25;
    text-align: left;
  }
}

.c-avatar {
  @extend .has-tooltip;
  @extend .tooltip-bottom;
  @extend .tooltip-center;
}
