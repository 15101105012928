@use "local_modules/bbr-bootstrap/theme/_variables.scss" as *;
@use "../../../styles/_variables.scss" as *;
@import "../../../styles/_variables-custom.scss";
.info {
  background: lighten($warning, 42%) !important;
}

// .header {
//   padding: 1.5rem 1.5rem;
//   color: $primary;
//   display: flex;
//   line-height: 140%;
//   flex-wrap: wrap;
//   justify-content: space-between;
//   align-items: center;
//   width: 100%;
//   i {
//     font-style: normal;
//     opacity: 0.9;
//   }
//   h4 {
//     color: inherit;
//     // font-size: 1.07em;
//   }
// }
// .item {
//   &.markeret {
//     p {
//       padding: 0 5px;
//       background: $warning;
//     }
//   }
// }
