.c-ejendom-mapcontainer {
  .ol-scale-line,
  .ol-overviewmap,
  .ol-zoom {
    display: none;
  }
}

.fullscreen-map-override {
  .legend {
    display: block;
    right: 260px;
    bottom: 2em;
  }

  .SKAT-BaseMap-SimpleLayerSwitcherControl {
    display: block;
    right: 94px;
    bottom: 2em;
  }

  .ol-legend {
    display: block;
    // top: 5em;
    // right: 3em;
    border-radius: 8px;
    overflow: hidden;
    border: none;
  }

  .ol-zoom {
    display: block;
    visibility: visible;
  }

  .map-container {
    left: block;
  }
}
