@use "sass:math";
@use "local_modules/bbr-bootstrap/theme/_variables.scss" as *;
@import "../../../../styles/_variables-custom.scss";
@import "../../Admin.module.scss";
.listrow {
  > div {
    margin-right: math.div(2, 3) * $spacer;
  }
  .titel {
    flex: 3 1 0px;
  }
  .forfatter {
    flex: 2 1 0px;
  }
  .dato {
    flex: 2 1 0px;
  }
  .tema {
    flex: 2 1 0px;
  }
  .forside {
    flex: 1 1 0px;
  }
}
.listrow {
  border-bottom: solid 1px tint-color($text-muted, 40%);
}
.listrow:hover {
  background-color: $white;
}

@media all and (max-width: $break-mobile) {
  .listrow {
    flex-direction: column;
    .forfatter,
    .dato,
    .forside {
      display: none;
    }
  }
}
