@use "node_modules/bootstrap/scss/functions" as *;
@use "local_modules/bbr-bootstrap/theme/_variables.scss" as *;
@import "../../../../styles/_variables-custom.scss";
.linkbuttonwrapper {
  margin-top: $spacer;
}
.linkHeader {
  &.linkbuttonwrapper {
    margin-left: $spacer;
  }
}

.loading {
  padding: 0 $spacer 0 0;
  @keyframes pulse {
    0% {
      background-color: $body-tertiary-bg;
    }
    100% {
      background-color: $secondary-bg-subtle;
    }
  }
  .prioritet {
    animation: pulse 1s infinite ease-in-out;
  }
  .text {
    flex: 1;
    display: flex;
    flex-direction: column;
    align-items: stretch;
    justify-content: space-evenly;
    margin-bottom: $spacer;
    margin-top: 2px;
    .line {
      animation: pulse 1s infinite ease-in-out;
      height: $spacer * 1.5;
    }
  }
}
