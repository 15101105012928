@use "local_modules/bbr-bootstrap/theme/_variables.scss" as *;
@use "../../../../../styles/_variables.scss" as *;
@import "../../../../../styles/_variables-custom.scss";
@import "Enhed.module.scss";

.containerContent.marginRight {
  right: 23 * $spacer;
}

.containerContent {
  position: fixed;
  z-index: 1001;
  left: 0;
  /* left pane width */
  top: $navbar-height;
  /* header height */
  right: 0;
  bottom: 0;
  overflow: auto;
  background-color: rgba(25, 47, 32, 0.3);
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  padding: 3rem 4rem 3rem 5rem;

  .inner {
    overflow: hidden;
    border-radius: 6px;
    position: relative;
    padding: 2.5 * $spacer 1.5 * $spacer;
    width: 100%;
    max-width: 960px;
    margin: auto;
    transform: translateX(-0.5rem);
    display: flex;
    flex-wrap: wrap;
    background: #ffffff;
    box-shadow: 0 2px 30px 0 rgba(46, 93, 89, 0.29);

    h5 {
      display: block;
      margin-bottom: 0.25rem;
      i {
        display: none;
      }
    }

    h4 {
      flex: 1 0 100%;
      margin-bottom: 1.5rem;
      font-weight: 600;
      padding-right: 3rem;

      > span {
        font-weight: 400;
      }
    }

    > div {
      flex: 1 1 40%;
      padding: 1rem 1.5rem;
      margin: 0;

      p {
        font-size: 1.06rem;
      }

      &:nth-of-type(4n + 1),
      &:nth-of-type(4n + 1) + * {
        background-color: $body-secondary-bg;
      }
    }

    > section {
      margin-top: 1.5rem;
      margin-bottom: 0;
    }
  }

  .close {
    display: flex;
    align-items: center;
    justify-content: center;
    color: $white;
    border-radius: 50%;
    width: 34px;
    height: 34px;
    background-color: $primary;
    position: absolute;
    top: 1.5 * $spacer;
    right: 1.5 * $spacer;
  }
}

@media (max-width: $break-laptop) {
  .containerContent {
    padding: 2.5rem 1.5rem 2.5rem 3.5rem;
  }
}

@media screen and (max-width: 1300px) {
  .containerContent .inner {
    > div {
      flex: 1 1 100%;

      &:nth-of-type(4n + 1),
      &:nth-of-type(4n + 1) + * {
        background-color: transparent;
      }

      &:nth-of-type(odd) {
        background-color: $body-secondary-bg;
      }
    }
  }
}

@media screen and (max-width: $break-tablet-md) {
  .containerContent.marginRight {
    right: 60px;
  }
}

@media screen and (max-width: $break-tablet-sm) {
  .containerContent {
    left: 60px;
  }
}

@media screen and (max-width: $break-mobile) {
  .containerContent.marginRight {
    right: 0;
  }

  .containerContent {
    padding: 1.5rem;
  }
}
