.c-toast {
  background-color: rgba(0, 0, 0, 0.243);
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  height: 100vh;
  height: 100dvh;
  width: 100vw;
  z-index: 3000;
  display: flex;
  justify-content: center;
  align-items: center;
  &-notification {
    // background-color: $white;
    min-width: 200px;
    border-radius: 6px;
    padding: 1rem 2rem;
    color: $secondary-text-darker;
    font-weight: 600;
    text-align: center;
    box-shadow: 3px 10px 21px 1px rgba(0, 0, 0, 0.15);
    display: flex;
    justify-content: center;
    align-items: center;
    column-gap: 0.5rem;
    &.status--ok {
      background-color: $secondary-bg-300;
      color: $primary;
    }
    &.status--error {
      background-color: $warning-bg-subtle;
      color: $primary;
    }
  }
}
