@use "node_modules/bootstrap/scss/functions" as *;
@use "local_modules/bbr-bootstrap/theme/_variables.scss" as *;
@use "../../../styles/_variables.scss" as *;
@import "../../../styles/_variables-custom.scss";
.container {
  display: flex;
  flex-direction: column;
  overflow: hidden;
  flex: 0 0 auto;

  button {
    border-bottom: 1px solid $border-color;
    text-align: left;
  }
  ::-webkit-scrollbar-track {
    border-left: 1px solid $secondary-bg-subtle;
  }

  .expand {
    position: absolute;
    top: 50%;
    transform: translate3d(0, -50%, 0);
    right: $spacer;
  }
  div {
    border-top: none;
  }
  h2 {
    font-size: 1.2em;
    font-weight: 600;
    margin: 0;
  }
}

.menuItem {
  width: 100%;
  padding: $spacer;
  display: flex;
  transition: background-color 200ms;
  cursor: pointer;
  &:hover {
    background-color: $secondary-bg-subtle;
  }
  &.selected {
    background-color: $secondary-bg-subtle;
  }
  h3 {
    font-size: 1.1em;
    font-weight: 400;
    margin: 0;
  }
}

.headeritem {
  display: flex;
  flex-direction: column;
  overflow: hidden;
  flex: 1 0 auto;

  h2 {
    span {
      float: right;
    }
  }
}
