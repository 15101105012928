html,
body {
  height: 100%;
  width: 100%;
  //   background-color: $white;
}

* {
  &:focus {
    outline: 0;
  }

  &:focus-visible {
    box-shadow: 0 0 0 2px rgba($primary, 0.5), 0 0 0 3px inset $secondary-bg-300 !important;
  }
}

#App {
  min-height: 100dvh;
  display: flex;
  flex-direction: column;
}

// Custom scrollbar styles
body {
  scrollbar-color: rgba(#000, 0.12) transparent;
  scrollbar-width: thin;
  overflow-y: scroll;
}

::-webkit-scrollbar {
  width: 14px;
}

::-webkit-scrollbar-track {
  background: transparent;
}

::-webkit-scrollbar-thumb {
  border: 4px solid transparent;
  background-clip: padding-box;
  background-color: rgba($secondary-text, 0.3);
  border-radius: 7px;
}

::-webkit-scrollbar-thumb:hover {
  background-color: rgba($primary, 0.3);
}

::-webkit-scrollbar-button {
  display: none;
}

// Tags style resets
a {
  text-decoration: none;
  font-size: 1em;
  color: $primary;
  font-weight: 600;
}

.fw-semibold {
  font-weight: 600;
}
.fw-medium {
  font-weight: 500;
}

.text-muted {
  & .hover-effect svg {
    color: rgba($primary, 0.6);
  }
  & p {
    color: rgba($primary, 0.6);
  }
  & h4 {
    color: rgba($primary, 0.6);
  }
  &:hover {
    & .hover-effect svg {
      color: $primary;
    }
    & p {
      color: $primary;
    }
    & h4 {
      color: $primary;
    }
  }
}
.hover-light:hover {
  background-color: rgba($primary, 0.1);
  transition: background-color 0.2s ease-in-out;
}
.hover-text-primary {
  color: $text-muted;
  transition: color 0.2s ease-in-out;
}
.hover-text-primary:hover {
  color: rgba($primary, 0.9);
  transition: color 0.2s ease-in-out;
}
.hover-primary-svg:hover {
  & .hover-svg svg {
    color: rgba($primary, 0.9);
    transition: color 0.2s ease-in-out;
  }
}

ul,
li {
  padding: 0;
  margin: 0;
  list-style: none;
}

button {
  font-size: 100%;
  font-family: inherit;
  border: none;
  padding: 0;
  margin: 0;
  background: transparent;
  width: auto;
  overflow: visible;
  background: transparent;
  color: inherit;
  line-height: normal;
  cursor: pointer;
  -webkit-font-smoothing: inherit;
  -moz-osx-font-smoothing: inherit;
  -webkit-appearance: none;
  appearance: none;
}

img {
  display: inline-block;
  vertical-align: middle;
  max-width: 100%;
  height: auto;
}

h5,
.h5 {
  font-weight: 700;
  line-height: 1.4em;
}

// Omskrives
a.internal,
button.internal {
  display: inline-block;
  padding-right: 40px;
  position: relative;
  text-decoration: none;

  svg {
    position: absolute;
    right: 5px;
    top: 20%;
    height: 12px;
    transition: all 200ms ease;
  }

  &:hover svg {
    right: 0;
  }
}

a.external {
  display: inline-flex;
  align-items: center;
  line-height: 1.4em;
  padding: 0;
  position: relative;

  &:hover {
    opacity: 0.8;
  }

  svg {
    margin-left: 0.5em;
  }
}

.links--visited {
  a.external {
    &:visited {
      color: darken($warning, 30%);
    }
  }
}

caption {
  text-align: left;
  font-style: italic;
  padding: 0.25em 0.5em 0.5em 0.5em;
}

.overflow-ellipsis-custom {
  p,
  h2 {
    font-weight: normal;
    width: 500px;
    max-width: 100%;
    overflow: hidden;
    text-overflow: ellipsis;
    white-space: nowrap;
    margin-bottom: 0;
  }
  h2 {
    font-size: large;
  }
}

.sr-only {
  //screenreader only
  position: absolute !important;
  width: 1px !important;
  height: 1px !important;
  padding: 0 !important;
  margin: -1px !important;
  overflow: hidden !important;
  clip: rect(0, 0, 0, 0) !important;
  white-space: nowrap !important;
  border: 0 !important;
}

.c-ejendom-mapcontainer {
  position: relative;
  width: 100%;
  height: 100%;
  overflow: hidden;
  max-height: 230px;
}

.link-all-unset {
  all: unset;
}
.cursor-pointer {
  cursor: pointer;
}

.text-overflow-ellipsis {
  max-width: 100%;
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
  // flex-grow: 1;
}

.formlink {
  padding: 1rem;
  width: 100%;
  text-align: start;
  background-color: #fff;
  appearance: none;
  font-size: 15px;
  border: none;
  cursor: pointer;
  transition: background-color 0.3s;
  &:hover {
    background-color: $secondary-bg-subtle;
  }
}

.badge-onit {
  background-color: $purple-100;
  color: $purple-800;
}
.badge-pause {
  color: $red-800;
}

.m-w-960 {
  max-width: 960px;
}
.m-w-1000 {
  max-width: 1000px;
}

//1 column grid
.c-grid-col1--parent {
  display: grid;
  grid-template-columns: 1fr;
  grid-template-rows: auto;
  align-items: center;
}

.c-grid-col1--child {
  grid-area: 1 / 1 / -1 / -1;
}

// @media all and (max-width: $break-laptop) {
//   html,
//   body {
//     font-size: 13px; // scales size-base
//   }

//   #App {
//     font-size: 14px; // scales font-sizes
//   }
// }
