@use "node_modules/bootstrap/scss/functions" as *;
@use "local_modules/bbr-bootstrap/theme/_variables.scss" as *;
@use "../../styles/_variables.scss" as *;
@import "../../styles/_variables-custom.scss";
.container {
  width: 100%;
  background-color: #fff;
  box-shadow: 0 0 20px 0 rgba(53, 66, 69, 0.1);
  margin-bottom: $spacer;
  border-radius: 6px;

  .content {
    padding: 0 3 * 0.5 * $spacer;
  }

  .content > div {
    padding: 1 * 0.5 * $spacer 0 3 * 0.5 * $spacer 0;
  }
}
.hover {
  &:hover {
    outline: 1px solid $primary;
  }
}

.content[aria-hidden="true"] {
  a,
  button {
    display: none;
  }
}
.titleblock {
  margin: 0 $spacer;
  max-width: 75%;
  overflow: hidden;

  h3 {
    font-size: 1.3em;
    font-weight: 400;
    text-align: left;
  }

  p {
    font-size: 0.9em;
    margin-top: 0.1em;
    width: 100%;
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
    text-align: left;
  }
}
.opgaveIcon {
  position: absolute;
  top: 50%;
  transform: translate3d(0, -50%, 0);
  right: 2 * $spacer;
  transition: opacity 0.2s;
  z-index: 100;
}

// Controls opgaveIcon - kan evt. laves smartere
.nestinglevelone .opgaveIcon {
  > div > span {
    display: inline-block;
  }
}

.header .opgaveIcon {
  > div:before {
    content: "";
  }
}

.expanded .opgaveIcon {
  > div > span {
    opacity: 0.6;
    transition: 0.2s;
    &:hover {
      opacity: 1;
    }
  }
}
// ***** //

.nested {
  position: relative;
}

.nestinglevelone {
  margin: 0 0 $spacer $spacer;
  width: calc(100% - #{$spacer});
}
.nestingleveltwo {
  margin: 0 0 $spacer $spacer * 2;
  width: calc(100% - #{$spacer * 2});
}

.nestinglevelthree {
  margin: 0 0 $spacer $spacer * 3;
  width: calc(100% - #{$spacer * 3});
}

.nested::before {
  position: absolute;
  top: -$spacer;
  content: "";
  display: block;
  border-left: 1px solid $secondary-bg-400;
  height: 4 * $spacer;
  border-bottom: 1px solid $secondary-bg-400;
  left: -10px;
  width: 10px;
}

.nested::after {
  position: absolute;
  bottom: -7px;
  content: "";
  display: block;
  border-left: 1px solid $secondary-bg-400;
  height: 100%;
  left: -10px;
}
.nested:last-child {
  &:after {
    display: none;
  }
}
.header {
  height: $spacer * 5.5;
  padding: 0 3 * 0.5 * $spacer;
  width: 100%;
  position: relative;
  display: flex;
  align-items: center;
  color: $primary;
  // cursor: pointer;
  > * {
    flex-shrink: 0;
  }
  > svg {
    opacity: 0.8;
  }
  .expand {
    display: flex;
    align-items: center;
    margin-right: $spacer;
  }
}

@media all and (max-width: $break-mobile) {
  .container {
    .content {
      padding: 0 $spacer;
    }

    .content > div {
      padding: 0 0 $spacer 0;
    }
  }
  .titleblock {
    p {
      display: none;
    }
  }

  .header {
    height: 5 * $spacer;
    padding: 0 $spacer;
    .expand {
      svg {
        width: 26px;
        height: 26px;
      }
    }
  }

  .opgaveIcon {
    right: $spacer;
    > div {
      width: auto;
      > span {
        display: none;
      }
    }
  }
}
