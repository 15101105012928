@use "local_modules/bbr-bootstrap/theme/_variables.scss" as *;
@use "../../../styles/_variables.scss" as *;
@import "../../../styles/_variables-custom.scss";
.container {
  width: 100%;
  h1 {
    padding-left: $spacer;
  }
}
.headercontainer {
  border-bottom: solid 1px $secondary;
  width: 100%;
}
