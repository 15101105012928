@use "node_modules/bootstrap/scss/functions" as *;
@use "local_modules/bbr-bootstrap/theme/_variables.scss" as *;
@use "../../styles/_variables.scss" as *;
@import "../../styles/_variables-custom.scss";
.usertoggle {
  display: flex;
  align-items: center;
  justify-content: flex-end;
  color: $primary;
  transition: color 300ms;
  &:hover {
    color: $secondary-text-darker;
  }
}

.menu {
  display: flex;
  flex-direction: column;
  width: 200px;
  background-color: $white;
  div,
  button {
    text-align: left;
    padding: $spacer;
    transition: background-color 300ms;
    &:hover {
      background-color: $secondary-bg-subtle;
    }
  }
}

.triangle {
  padding: 3px;
  svg {
    transition: transform 300ms;
    transform: rotate(0deg);
  }
}

.active {
  .triangle {
    svg {
      transform: rotate(180deg);
    }
  }
}

.formlink {
  padding: 1rem;
  width: 100%;
  text-align: start;
  background-color: #fff;
  appearance: none;
  font-size: 15px;
  border: none;
  cursor: pointer;
  transition: background-color 0.3s;
  &:hover {
    background-color: $secondary-bg-subtle;
  }
}
