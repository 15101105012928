$icomoon-font-family: "bbr" !default;
$icomoon-font-path: "fonts" !default;

$icon-BBR-erhverv: "\75";
$icon-BBR-spoergsmaal: "\61";
$icon-BBR-advarsel: "\62";
$icon-BBR-ukendt-bygning: "\63";
$icon-BBR-smaabygning: "\64";
$icon-BBR-fritidsformaal: "\65";
$icon-BBR-carport-garage: "\66";
$icon-BBR-olietank: "\67";
$icon-BBR-silo: "\68";
$icon-BBR-halmfyr-biogas-mv: "\69";
$icon-BBR-vandanlaeg: "\6a";
$icon-BBR-vindmoelle: "\6b";
$icon-BBR-energi: "\6c";
$icon-BBR-ensilage-planlager: "\6d";
$icon-BBR-tank: "\6e";
$icon-BBR-andet-anlaeg: "\6f";
$icon-BBR-ukendt-TEK: "\70";
$icon-BBR-industri: "\71";
$icon-BBR-helaarsbeboelse: "\72";
$icon-BBR-landbrug: "\73";
$icon-BBR-institution: "\74";

@font-face {
  font-family: "#{$icomoon-font-family}";
  src: url("#{$icomoon-font-path}/#{$icomoon-font-family}.eot?snhttt");
  src: url("#{$icomoon-font-path}/#{$icomoon-font-family}.eot?snhttt#iefix")
      format("embedded-opentype"),
    url("#{$icomoon-font-path}/#{$icomoon-font-family}.ttf?snhttt")
      format("truetype"),
    url("#{$icomoon-font-path}/#{$icomoon-font-family}.woff?snhttt")
      format("woff"),
    url("#{$icomoon-font-path}/#{$icomoon-font-family}.svg?snhttt##{$icomoon-font-family}")
      format("svg");
  font-weight: normal;
  font-style: normal;
}

[class^="icon-"],
[class*=" icon-"] {
  /* use !important to prevent issues with browser extensions that change fonts */
  font-family: "#{$icomoon-font-family}" !important;
  speak: none;
  font-style: normal;
  font-weight: normal;
  font-variant: normal;
  text-transform: none;
  line-height: 1;

  /* Better Font Rendering =========== */
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

.icon-BBR-erhverv {
  &:before {
    content: $icon-BBR-erhverv;
  }
}
.icon-BBR-spoergsmaal {
  &:before {
    content: $icon-BBR-spoergsmaal;
  }
}
.icon-BBR-advarsel {
  &:before {
    content: $icon-BBR-advarsel;
  }
}
.icon-BBR-ukendt-bygning {
  &:before {
    content: $icon-BBR-ukendt-bygning;
  }
}
.icon-BBR-smaabygning {
  &:before {
    content: $icon-BBR-smaabygning;
  }
}
.icon-BBR-fritidsformaal {
  &:before {
    content: $icon-BBR-fritidsformaal;
  }
}
.icon-BBR-carport-garage {
  &:before {
    content: $icon-BBR-carport-garage;
  }
}
.icon-BBR-olietank {
  &:before {
    content: $icon-BBR-olietank;
  }
}
.icon-BBR-silo {
  &:before {
    content: $icon-BBR-silo;
  }
}
.icon-BBR-halmfyr-biogas-mv {
  &:before {
    content: $icon-BBR-halmfyr-biogas-mv;
  }
}
.icon-BBR-vandanlaeg {
  &:before {
    content: $icon-BBR-vandanlaeg;
  }
}
.icon-BBR-vindmoelle {
  &:before {
    content: $icon-BBR-vindmoelle;
  }
}
.icon-BBR-energi {
  &:before {
    content: $icon-BBR-energi;
  }
}
.icon-BBR-ensilage-planlager {
  &:before {
    content: $icon-BBR-ensilage-planlager;
  }
}
.icon-BBR-tank {
  &:before {
    content: $icon-BBR-tank;
  }
}
.icon-BBR-andet-anlaeg {
  &:before {
    content: $icon-BBR-andet-anlaeg;
  }
}
.icon-BBR-ukendt-TEK {
  &:before {
    content: $icon-BBR-ukendt-TEK;
  }
}
.icon-BBR-industri {
  &:before {
    content: $icon-BBR-industri;
  }
}
.icon-BBR-helaarsbeboelse {
  &:before {
    content: $icon-BBR-helaarsbeboelse;
  }
}
.icon-BBR-landbrug {
  &:before {
    content: $icon-BBR-landbrug;
  }
}
.icon-BBR-institution {
  &:before {
    content: $icon-BBR-institution;
  }
}
