@use "local_modules/bbr-bootstrap/theme/_variables.scss" as *;
@use "../../../styles/_variables.scss" as *;
@import "../../../styles/_variables-custom.scss";
.container {
  display: flex;
  flex-direction: column;
  align-items: stretch;
  padding: $spacer * 3;
  flex: 1;
  // min-height: calc(100vh - #{$navbar-height});
}

@media all and (max-width: $break-mobile) {
  .container {
    padding: $spacer;
  }
}
