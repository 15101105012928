/* Custom button style kode */
@if $custom-bbr-components {

// Subtle loop colors
@each $color, $value in $theme-colors {
    .btn-#{$color}-subtle { // main subtle color style
        @include button-variant(
            tint-color($value, 80%),
            tint-color($value, 80%),
            $color: $value,
            $hover-background: tint-color($value, $btn-hover-bg-tint-amount),
            $hover-border: tint-color($value, $btn-hover-border-tint-amount),
            $active-background: tint-color($value, $btn-active-bg-tint-amount),
            $active-border: tint-color($value, $btn-active-border-tint-amount)
        );
    }
    .btn-outline-#{$color}-subtle { // outline subtle color style
        @include button-variant(
            transparent,
            $border: tint-color($value, 80%),
            $color: $value,
            $hover-color: $value,
            $hover-background: transparent,
            $hover-border: $value,
            $active-background: tint-color($value, $btn-active-bg-tint-amount),
            $active-border: $value
        );
    }
}

}