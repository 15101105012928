@use "sass:math";

.navigation-fixed {
  width: 100%;
  max-width: 100%;
  min-height: 80px;
  display: flex;
  justify-content: space-between;
  align-items: center;
  position: fixed;
  padding: 0.5rem 1rem 0.5rem 1rem;
  gap: 0.75rem;
  background: linear-gradient($white 70%, rgba($white, 0.8));
  z-index: 1200;
  border-bottom: 1px solid transparent;
  &.navigation-fixed--border {
    border-bottom: 1px solid rgba($primary, 0.1);
    background: $white;
  }
}

.c-navlink {
  font-size: 0.92em;
  padding: 0.8rem 1.5rem;
  display: flex;
  align-items: center;
  justify-content: center;
  text-align: center;
  text-decoration: none;
  color: $text-muted;
  margin-right: $spacer * 2;
  transition: color 300ms;

  &:hover {
    color: $primary;
    transition: color 300ms;
  }
}

.hamburger {
  display: flex;
  align-items: center;
}
.hamburgermenu {
  width: 300px;
  height: 100%;
  border: 1px solid $border-color;
  background-color: $white;
  right: 50% !important;
  margin-right: -150px;
  a {
    padding: 1.5 * $spacer;
    border-top: 1px solid $border-color;
    margin-top: -1px;
    color: $secondary-text;
    &.selected {
      color: $primary;
    }
    &:hover {
      background-color: $light;
    }
  }
  .formlink {
    padding: 1.5 * $spacer;
    border: none;
    border-top: 1px solid $border-color;
    width: 100%;
    text-align: start;
    font-size: 15px;
    background-color: #fff;
    color: $secondary-text;
    cursor: pointer;
    &:hover {
      background-color: $light;
    }
  }
}

@media all and (max-width: $break-tablet-sm) {
  .linkscontainer {
    margin-top: 0;
    // margin-left: auto;
  }
  .links {
    display: none;
  }

  .navigation-fixed {
    gap: 0;
    .chevron {
      display: none;
    }
  }

  .container-xl {
    --bs-gutter-x: 0;
  }
}
@media all and (min-width: ($break-tablet-sm + 1px)) {
  // .hamburger,
  // div.hamburgermenu {
  //   display: none;
  // }
}

@media all and (max-width: $break-mobile) {
  // .linkscontainer {
  //   margin-left: 0;
  // }

  // .hamburger {
  //   margin: 0 1rem;
  // }

  .hamburgermenu {
    // margin-top: 1.5rem !important;
    margin-right: -50vw;
    width: 100vw;
    border-left-color: transparent !important;
    border-right-color: none !important;
    border-top-color: $secondary !important;
    border-bottom-color: $secondary !important;
  }
}
