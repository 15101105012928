@use "local_modules/bbr-bootstrap/theme/_variables.scss" as *;
@import "../../../../styles/_variables-custom.scss";

.container {
  background-color: $white;

  ul {
    display: flex;
    flex-wrap: wrap;
    padding: $spacer 0 3 * 0.5 * $spacer 0;

    li {
      flex: 1 1 40%;
      padding: $spacer 3 * 0.5 * $spacer;

      &:nth-of-type(4n + 3),
      &:nth-of-type(4n + 3) + * {
        background-color: transparent;
      }

      &:nth-of-type(4n + 1),
      &:nth-of-type(4n + 1) + * {
        background-color: $body-secondary-bg;
      }
    }
  }

  p {
    font-size: 1.06em;
    display: inline-block;
  }

  sup {
    font-size: 0.65em;
    font-weight: 600;
  }
}

@media screen and (max-width: 1300px) {
  .container ul li {
    flex: 1 1 100%;

    &:nth-of-type(4n + 1),
    &:nth-of-type(4n + 1) + * {
      background-color: transparent;
    }

    &:nth-of-type(odd) {
      background-color: $body-secondary-bg;
    }
  }
}

@media all and (max-width: $break-mobile) {
  .container ul li {
    padding: $spacer;
  }
}
