@use "local_modules/bbr-bootstrap/theme/_variables.scss" as *;
@use "../../styles/_variables.scss" as *;
@import "../../styles/_variables-custom.scss";
.container {
  padding: $navbar-height 0 0 0;
  height: 100%;
  width: 100%;
  > div,
  footer {
    width: 100%;
    padding: 0 4 * $spacer;
  }
  h2 {
    @include main-h2;
    > * {
      display: inline-block;
      vertical-align: middle;
    }
    svg {
      margin-right: $spacer;
    }
  }
}
.grid {
  width: 910px;
  padding: $spacer * 4.5 0 $spacer * 5 0;
  margin: 0 auto;
}
.intro {
  > div {
    flex: 1 1;
  }
  .col1 {
    width: 70%;
  }

  .col2 {
    width: 30%;
  }
  h1 {
    font-size: 3rem;
    margin-bottom: 0.5em;
    color: $secondary;
    line-height: 130%;
  }
  p {
    margin-bottom: 1.5rem;
    margin-right: 2rem;
    line-height: 1.6;
  }
  .grid {
    padding-bottom: 2.5 * $spacer;
    display: flex;
  }
}

@media all and (max-width: $break-tablet-md) {
  .grid {
    width: 100%;
  }
}

@media all and (max-width: $break-mobile) {
  .container {
    > div,
    footer {
      padding: 0 2 * $spacer;
    }
  }

  .grid {
    padding: 3 * $spacer 0;
  }
  .intro {
    .col1 {
      width: 100%;
    }
    .col2 {
      display: none;
    }
  }

  .grid {
    flex-direction: column;
    button {
      margin-top: 3 * $spacer;
    }
  }
  .logos {
    flex-wrap: wrap;
  }
}
